import { useDispatch } from "react-redux";
import { API_GET_USER_DETAILS } from "../../actions/ApiConstants";
import { REDUCER_SET_USER_DETAILS } from "../../reducer/ReducerConstants";
import { setAccessToken, setAccount, setIdToken } from "../SessionUtils";
import { navigate } from "../Utility";
import { completeAuthentication, logoutForTars, removeUser } from "./AuthServiceForTars";
import { getUserCall } from "../../actions/performAction";
import { useEffect, useRef } from "react";

export const AuthCallbackComponentForTars = () => {

    const hasRun = useRef(false);
    const dispatch = useDispatch();

    useEffect(() => {
        if (hasRun.current) return;
        hasRun.current = true;
        removeUser();
        completeAuthentication().then((userData) => {

            if (userData) {
                console.log("user", userData);
                setIdToken(userData.id_token);
                setAccessToken(userData.access_token);
                setAccount(userData.profile && userData.profile.FirstName ? userData.profile.FirstName : userData.profile.userinfo[0].name);
                dispatch(getUserCall(API_GET_USER_DETAILS, REDUCER_SET_USER_DETAILS));

                navigate("/");
            } else {
                logoutForTars();
            }
        });
    });
    return (
        <div className="flex justify-content-center w-full">
            <div className="fixed text-primary"
                style={{ top: "45%" }}
            >
                <h1>Please wait...</h1>
            </div>
        </div>
    );
};