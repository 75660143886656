import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Arrival } from "./components/pages/Arrival";
import { Home } from "./components/pages/Home";
import Container from "./components/organisms/Container";
import { ErrorPage } from "./components/pages/ErrorPage";
import { Lcp } from "./components/pages/LCP";
import { Master } from "./components/pages/Master";
import { FinalArrival } from "./components/pages/FinalArrival";
import { AuthCallbackComponentForTars } from "./util/oidc/AuthCallbackComponentForTars";
import { AuthCallbackComponent } from "./util/AuthCallbackComponent";

const LpRoutes = () => {
  return (
    <Router>
      <Container>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/arrival" element={<Arrival />} />
          <Route path="/lcp" element={<Lcp />} />
          <Route path="/master" element={<Master />} />
          <Route path="/finalArrival" element={<FinalArrival />} />
          <Route path="/errorPage" element={<ErrorPage />} />
          <Route path="/callbacktars" element={<AuthCallbackComponentForTars />} />
          <Route path="/callbackazure" element={<AuthCallbackComponent />} />
        </Routes>
      </Container>
    </Router>
  );
};

export default LpRoutes;
