import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.jsx";
import "primereact/resources/primereact.min.css"; //core css
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./LpToolsTheme.css";
import "./assets/General.css";
import { Provider } from "react-redux";
import { store } from "./util/store.jsx";
import "./i18n.jsx";
import {
  authenticateWithAzureAD,
  getPublicClientObject,
} from "./util/AuthService.jsx";
import { getLoginMethod } from "./util/SessionUtils.jsx";
import { LOGIN_METH_AZURE } from "./util/Constants.jsx";
import { loadUser } from "redux-oidc";
import { getUserManager, renewToken } from "./util/oidc/AuthServiceForTars.jsx";

export const pubClientApp = getPublicClientObject(); //For initializing auth process instance For Azure AD
pubClientApp.initialize();

var userManager = getUserManager();
loadUser(store, userManager);

setInterval(
  () =>
    getLoginMethod() === LOGIN_METH_AZURE
      ? authenticateWithAzureAD(pubClientApp)
      : renewToken(),
  3000000
); //Refresh access token every 50 mins

ReactDOM.createRoot(document.getElementById("root")).render(
  // <React.StrictMode>
  <Provider store={store}>
    <App msalInstance={pubClientApp} />
  </Provider>
  // </React.StrictMode>
);
