import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { useTranslation } from "react-i18next";
import { getCallWithoutReducer } from "../../actions/performAction";
import { API_GET_VIN_DETAILS } from "../../actions/ApiConstants";
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { Toast } from "primereact/toast";
import { getPaginationRows, sortDateInDataTable } from "../../util/Utility";
import "./VinDetailsDialogForArrival.css";

export const VinDetailsDialogForArrival = (props) => {
  useEffect(() => {

    // let vinArrivalSearchDto = {
    //   selectedTransportId: props.selectedTransportId,
    //   pageNumber: "0"
    // }

    dispatch(
      getCallWithoutReducer(
        API_GET_VIN_DETAILS,
        props.selectedTransportId,
        setVinDetailsResult,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const [vinDetailsResult, setVinDetailsResult] = useState();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const onFailure = (error) => {
    toast.current.show({ severity: "error", summary: "Error", detail: error });
  };
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      Vin Details for Transport Id - {props.selectedTransportId.transportId}
    </div>
  );

  const footerContent = (
    <div className="flex justify-content-end">
      <div>
        <Button
          label={t("button.cancel")}
          icon="pi pi-times"
          onClick={() => props.setVinDetailsDialogForArrivalVisible(false)}
          size="small"
        />
      </div>
    </div>
  );

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Dialog
        visible={props.vinDetailsDialogForArrivalVisible}
        modal
        header={headerElement}
        footer={footerContent}
        onHide={() => props.setVinDetailsDialogForArrivalVisible(false)}
        closable
        style={{ width: "60rem" }}
        breakpoints={{ "1000px": "60%", "800px": "70%", "667px": "30rem" }}
      >
        <div className="vinDialogTableClass">
          <DataTable
            value={vinDetailsResult}
            dataKey={"vin"}
            header={t("label.vinsDetails")}
            stripedRows
            showGridlines
            scrollable
            paginator
            rows={20}
            rowsPerPageOptions={getPaginationRows()}
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
          >
            <Column field="vin" header={t("label.vins")} sortable></Column>
            <Column field="lp" header={t("label.lp")} sortable></Column>
            <Column
              field="location"
              header={t("label.location")}
              sortable
            ></Column>
            <Column field="eta" header={t("label.eta")}
              sortable
              sortFunction={(e) => sortDateInDataTable(e, "eta")}
            ></Column>
            <Column
              field="status"
              header={t("label.status")}
              body={props.getBackgroundForStatusCol}
              sortable
            ></Column>
          </DataTable>
        </div>
      </Dialog>
    </>
  );
};
