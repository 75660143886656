import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import {
  getLabelfromValue,
  mapSelectedSubCompoundsToDto,
  mapSelectedSubCompoundsToUi,
  shouldElementBeDisabled,
} from "../../util/Utility";
import {
  ACTIVE,
  COMPOUND_TYPE_MAIN,
  COMPOUND_TYPE_SUB_COMPOUND,
  DIALOG_BOX_EDIT,
  DIALOG_BOX_FOR_ADD,
  IN_ACTIVE,
} from "../../util/Constants";
import { isValidAlphaNumeric } from "../../util/CommonValidations";
import { ArrivalLcpSelectionTable } from "./ArrivalLcpSelectionTable";
import { useDispatch } from "react-redux";
import {
  getCallWithoutReducer,
  postCallWithoutReducer,
} from "../../actions/performAction";
import {
  API_GET_SUB_COMPOUNDS,
  API_POST_ADD_COMPOUND,
  API_POST_EDIT_COMPOUND,
} from "../../actions/ApiConstants";
import { Toast } from "primereact/toast";

export const AddOrEditCompoundDialog = (props) => {
  useEffect(() => {
    dispatch(
      getCallWithoutReducer(
        API_GET_SUB_COMPOUNDS,
        "",
        setSubCompoundList,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const toast = useRef(null);
  const [compoundName, setCompoundName] = useState(
    props.rowData ? props.rowData.compoundName : ""
  );
  const [compoundCode, setCompoundCode] = useState(
    props.rowData ? props.rowData.compoundCode : ""
  );
  const [status, setStatus] = useState(
    props.rowData ? (props.rowData.status ? ACTIVE : IN_ACTIVE) : ACTIVE
  );
  const [compoundType, setCompoundType] = useState(
    props.rowData ? props.rowData.compoundType : null
  );

  const [truckingCompanyList] = useState(
    props.truckingCompanyList ? props.truckingCompanyList : []
  );

  const [truckingCompanies, setTruckingCompanies] = useState(
    props.rowData ? props.rowData.truckingCompanies : []
  );
  const [selectedSubCompounds, setSelectedSubCompounds] = useState(
    props.rowData && props.rowData.subCompounds
      ? mapSelectedSubCompoundsToUi(props.rowData.subCompounds) //String array as a value
      : []
  );
  const [selectedSubCompoundsDto, setSelectedSubCompoundsDto] = useState(
    //array of dtos
    props.rowData && props.rowData.subCompounds
      ? mapSelectedSubCompoundsToDto(props.rowData.subCompounds)
      : []
  );
  const [dialogBoxFor] = useState(props.dialogBoxFor);
  const [subCompoundList, setSubCompoundList] = useState([]);
  const dispatch = useDispatch();
  const ref = useRef();

  // Error States
  const [errorInCompoundType, setErrorInCompoundType] = useState({
    error: false,
    message: "",
  });
  const [errorInCompoundName, setErrorInCompoundName] = useState({
    error: false,
    message: "",
  });
  const [errorInCompoundCode, setErrorInCompoundCode] = useState({
    error: false,
    message: "",
  });
  const [errorInTruckingCompanies, setErrorInTruckingCompanies] = useState({
    error: false,
    message: "",
  });
  const [errorInStatus, setErrorInStatus] = useState({
    error: false,
    message: "",
  });

  // const subCompoundList = [
  //     { label: "PT-AIR-1", value: "PT-AIR-1" },
  //     { label: "PT-AIR-2", value: "PT-AIR-2" },
  //     { label: "PT-AIR-3", value: "PT-AIR-3" },
  // ]

  const mapSubCompoundToDto = (selectedSubCompoundList) => {
    let newArr = [];
    if (selectedSubCompoundsDto.length === 0) {
      selectedSubCompoundList.forEach((subCompound) => {
        newArr.push({
          subCompoundName: getLabelfromValue(subCompound, subCompoundList),
          subCompoundCode: subCompound,
          arrival: false,
          loadComplete: false,
        });
      });
      setSelectedSubCompoundsDto(newArr);
      return;
    }

    for (let i = 0; i < selectedSubCompoundList.length; i++) {
      var flag = false;
      for (let j = 0; j < selectedSubCompoundsDto.length; j++) {
        if (
          selectedSubCompoundList[i] === selectedSubCompoundsDto[j].subCompound
        ) {
          newArr.push({
            subCompoundName: getLabelfromValue(
              selectedSubCompoundList[i],
              subCompoundList
            ),
            subCompoundCode: selectedSubCompoundList[i],
            arrival: selectedSubCompoundsDto[j].arrival,
            loadComplete: selectedSubCompoundsDto[j].loadComplete,
          });
          flag = true;
          break;
        }
      }
      if (!flag) {
        newArr.push({
          subCompoundName: getLabelfromValue(
            selectedSubCompoundList[i],
            subCompoundList
          ),
          subCompoundCode: selectedSubCompoundList[i],
          arrival: false,
          loadComplete: false,
        });
      }
    }
    setSelectedSubCompoundsDto(newArr);
  };

  const onMultiSelectChange = (e) => {
    setSelectedSubCompounds(e.value);
    mapSubCompoundToDto(e.value);
  };

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      {dialogBoxFor === DIALOG_BOX_FOR_ADD
        ? t("label.addCompound")
        : t("label.editCompound")}
    </div>
  );

  const onClickSave = () => {
    let flag = false;
    let compoundCodeRegex = /^[A-Z,a-z]{2}-[A-Z,a-z,0-9, ]{2,5}-[0-9]{1,2}$/;

    if (dialogBoxFor === DIALOG_BOX_FOR_ADD) {
      if (!compoundType) {
        setErrorInCompoundType({
          error: true,
          message: "Please select compound type.",
        });
        flag = true;
      }

      if (!isValidAlphaNumeric(compoundName)) {
        setErrorInCompoundName({
          error: true,
          message: "Please enter compound name.",
        });
        flag = true;
      }

      if (!compoundCode) {
        setErrorInCompoundCode({
          error: true,
          message: "Please enter compound code.",
        });
        flag = true;
      } else if (!compoundCodeRegex.test(compoundCode)) {
        setErrorInCompoundCode({
          error: true,
          message: "Please enter a valid compound code.",
        });
        flag = true;
      }

      if (
        compoundType === COMPOUND_TYPE_MAIN &&
        truckingCompanies.length === 0
      ) {
        setErrorInTruckingCompanies({
          error: true,
          message: "Please select alteast one trucking company.",
        });
        flag = true;
      }
    }

    if (!status) {
      setErrorInStatus({ error: true, message: "Please select status." });
      flag = true;
    }

    if (!flag) {
      let addCompoundDto = {
        compoundType: compoundType,
        compoundName: compoundName,
        compoundCode: compoundCode,
        truckingCompanies:
          compoundType === COMPOUND_TYPE_MAIN ? truckingCompanies : [],
        status: status === ACTIVE ? true : false,
        compoundMappingDtos:
          compoundType === COMPOUND_TYPE_MAIN
            ? ref.current?.combineSelections()
            : [],
      };
      if (dialogBoxFor === DIALOG_BOX_FOR_ADD) {
        dispatch(
          postCallWithoutReducer(
            API_POST_ADD_COMPOUND,
            addCompoundDto,
            props.onSuccessSaveAddOrEditCompound,
            onFailure
          )
        );
        return;
      }
      if (dialogBoxFor === DIALOG_BOX_EDIT) {
        dispatch(
          postCallWithoutReducer(
            API_POST_EDIT_COMPOUND,
            addCompoundDto,
            props.onSuccessSaveAddOrEditCompound,
            onFailure
          )
        );
        return;
      }
    }
  };

  const onFailure = (error) => {
    toast.current.show({ severity: "error", summary: "Error", detail: error });
  };

  const footerContent = (
    <div className="flex justify-content-end">
      <div>
        <Button
          label={t("button.save")}
          icon="pi pi-save"
          onClick={onClickSave}
          size="small"
        />
      </div>
      <div>
        <Button
          label={t("button.cancel")}
          icon="pi pi-times"
          onClick={() => props.setAddOrEditDialogVisible(false)}
          size="small"
        />
      </div>
    </div>
  );

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Dialog
        visible={props.addOrEditDialogVisible}
        modal
        header={headerElement}
        footer={footerContent}
        onHide={() => props.setAddOrEditDialogVisible(false)}
        closable
        style={{ maxWidth: "600px", height: "470px" }}
        // breakpoints={{ '2000px': '40rem', '1000px': "70%", '800px': "70%", '667px': '20rem' }}
        breakpoints={{
          "2000px": "40rem",
          "1000px": "50rem",
          "800px": "40rem",
          "667px": "25rem",
        }}
      >
        <div className="grid mt-1 ml-2">
          <div className="mb-2">
            <label htmlFor="compoundType" className="mr-3 ">
              {t("label.compoundType")}: <b className="text-primary">*</b>
            </label>

            <RadioButton
              inputId="compoundType"
              name="compoundType"
              value={COMPOUND_TYPE_MAIN}
              onChange={(e) => {
                setCompoundType(e.value);
                setErrorInCompoundType({ error: "", message: "" });
              }}
              checked={compoundType === COMPOUND_TYPE_MAIN}
              disabled={shouldElementBeDisabled(dialogBoxFor)}
            />
            <label htmlFor="compoundType" className="ml-2 mr-2">
              {t("label.mainCompound")}
            </label>

            <RadioButton
              inputId="status"
              name="compoundType"
              value={COMPOUND_TYPE_SUB_COMPOUND}
              onChange={(e) => {
                setCompoundType(e.value);
                setErrorInCompoundType({ error: "", message: "" });
              }}
              checked={compoundType === COMPOUND_TYPE_SUB_COMPOUND}
              disabled={shouldElementBeDisabled(dialogBoxFor)}
            />
            <label htmlFor="compoundType" className="ml-2">
              {t("label.subCompound")}
            </label>
            <div className="flex justify-content-center">
              <span
                className="p-error block pt-1"
                style={{
                  visibility: `${errorInCompoundType.error ? "" : "hidden"}`,
                }}
              >
                {errorInCompoundType.message}
              </span>
            </div>
          </div>

          <div className="mb-2 ml-2 mr-2">
            <span> | </span>
          </div>

          <div className=" mb-2">
            <label htmlFor="status" className=" mr-3">
              {t("label.status")}: <b className="text-primary">*</b>
            </label>

            <RadioButton
              inputId="status"
              name="status"
              value={ACTIVE}
              onChange={(e) => {
                setStatus(e.value);
                setErrorInStatus({ error: false, message: "" });
              }}
              checked={status === ACTIVE}
            />
            <label htmlFor="status" className="ml-2 mr-2">
              {t("label.active")}
            </label>

            <RadioButton
              inputId="status"
              name="status"
              value={IN_ACTIVE}
              onChange={(e) => {
                setStatus(e.value);
                setErrorInStatus({ error: false, message: "" });
              }}
              checked={status === IN_ACTIVE}
            />
            <label htmlFor="status" className="ml-2">
              {t("label.inActive")}
            </label>
            <div className="flex justify-content-center">
              <span
                className="p-error block pt-1"
                style={{ visibility: `${errorInStatus.error ? "" : "hidden"}` }}
              >
                {errorInStatus.message}
              </span>
            </div>
          </div>
        </div>

        <div className="grid justify-content-center mt-1">
          <div className="col mt-3">
            <span className="p-float-label w-10rem">
              <InputText
                id="compoundName"
                value={compoundName}
                onChange={(e) => {
                  setCompoundName(e.target.value);
                  setErrorInCompoundName({ error: false, message: "" });
                }}
                className={`w-full ${
                  errorInCompoundName.error ? "p-invalid" : ""
                }`}
                disabled={shouldElementBeDisabled(dialogBoxFor)}
              />
              <label htmlFor="compoundName">
                {t("label.compoundName")} <b className="text-primary"> *</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInCompoundName.error ? "" : "hidden"}`,
              }}
            >
              {errorInCompoundName.message}
            </span>
          </div>

          <div className="w-7 col mt-3">
            <span className="p-float-label w-10rem">
              <InputText
                id="compoundCode"
                value={compoundCode}
                onChange={(e) => {
                  setCompoundCode(e.target.value);
                  setErrorInCompoundCode({ error: false, message: "" });
                }}
                className={`w-full ${
                  errorInCompoundCode.error ? "p-invalid" : ""
                }`}
                disabled={shouldElementBeDisabled(dialogBoxFor)}
              />
              <label htmlFor="compoundCode">
                {t("label.compoundCode")}
                <b className="text-primary"> *</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInCompoundCode.error ? "" : "hidden"}`,
              }}
            >
              {errorInCompoundCode.message}
            </span>
          </div>

          <div
            className="col w-7 mt-3"
            style={{
              visibility: `${
                compoundType === COMPOUND_TYPE_MAIN ? "" : "hidden"
              }`,
            }}
          >
            <span className="p-float-label w-10rem">
              <MultiSelect
                value={truckingCompanies}
                onChange={(e) => {
                  setTruckingCompanies(e.value);
                  setErrorInTruckingCompanies({ error: false, message: "" });
                }}
                options={truckingCompanyList}
                optionLabel="label"
                maxSelectedLabels={3}
                className={`w-full ${
                  errorInTruckingCompanies.error ? "p-invalid" : ""
                }`}
                showClear
                display="chip"
                filter
              />
              <label>
                {t("label.truckingCompanies")}
                <b className="text-primary"> *</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{
                visibility: `${errorInTruckingCompanies.error ? "" : "hidden"}`,
              }}
            >
              {errorInTruckingCompanies.message}
            </span>
          </div>

          <div
            className="col mt-3"
            style={{
              visibility: `${
                compoundType === COMPOUND_TYPE_MAIN ? "" : "hidden"
              }`,
            }}
          >
            <span className="p-float-label w-10rem md:w-full lg:w-full">
              <MultiSelect
                value={selectedSubCompounds}
                onChange={(e) => onMultiSelectChange(e)}
                options={subCompoundList}
                optionLabel="label"
                maxSelectedLabels={3}
                className="w-full"
                showClear
                display="chip"
                filter
              />
              <label htmlFor="Compound">{t("label.subCompound")}</label>
            </span>
          </div>
        </div>
        <div className="mt-3">
          <ArrivalLcpSelectionTable
            ref={ref}
            selectedSubCompounds={selectedSubCompoundsDto}
          />
        </div>
      </Dialog>
    </>
  );
};
