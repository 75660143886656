import { useMsal } from "@azure/msal-react";
import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { logout } from "../../util/AuthService";
import { ERROR_NETWORK, LOGIN_METH_AZURE, SESSION_EXPIRED } from "../../util/Constants";
import { getLoginMethod } from "../../util/SessionUtils";
import { logoutForTars } from "../../util/oidc/AuthServiceForTars";

export const ErrorPage = (props) => {
  const { instance } = useMsal();
  const { state } = useLocation();
  const { errorResponse } = state;
  const { t } = useTranslation();
  const hasRun = useRef(false);

  useEffect(() => {
    if (hasRun.current) return;
    hasRun.current = true;
    if (
      (errorResponse && errorResponse && errorResponse === 401) ||
      (errorResponse && errorResponse && errorResponse === 403) ||
      (errorResponse && errorResponse && errorResponse === ERROR_NETWORK)
    ) {
      setTimeout(
        function () {

          if (getLoginMethod() === LOGIN_METH_AZURE) {
            logout(instance)
          }
          else {
            logoutForTars();
          }
        },
        4000
      );
    }
    else {
      if (getLoginMethod() === LOGIN_METH_AZURE) {
        logout(instance)
      }
      else {
        logoutForTars();
      }
    }
  }, []);

  return (
    <>
      <div className="flex justify-content-center">
        <div className="fixed bottom-50 text-primary" style={{ top: "40%" }}>
          <div className="flex justify-content-center">
            <h1>{t("errorMessage.errorPageHeading")}</h1>
          </div>
          <div className="flex justify-content-center">
            <h1>
              {errorResponse && errorResponse === 401
                ? t("message.unauthorized")
                : null}
              {errorResponse && errorResponse === 403
                ? t("message.forbidden")
                : null}
              {errorResponse && errorResponse === ERROR_NETWORK
                ? t("errorMessage.networkError")
                : null}
              {errorResponse && errorResponse === SESSION_EXPIRED
                ? t("errorMessage.sessionExpired")
                : null}
            </h1>
          </div>
          <div className="flex justify-content-center">
            <h1>{t("message.redirecting")}</h1>
          </div>
        </div>
      </div>
    </>
  );
};
