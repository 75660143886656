// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-message-image {
    width: 100%;
    height: 300px;
}

.responsive-fieldset{
    width: 50%;
}

/* Media Queries for Different Screen Sizes */

@media (max-width: 1300px) {
    .responsive-message-image {
        width: 100%;
        height: 300px;
    }
    .responsive-fieldset{
        width: 50%;
    }
}

@media (max-width: 900px) {
    .responsive-message-image {
        width: 100%;
        height: 300px;
    }
    .responsive-fieldset{
        width: 80%;
    }
}

@media (max-width: 768px) {
    .responsive-message-image {
        width: 100%;
        height: 300px;
    }
    .responsive-fieldset{
        width: 90%;
    }
}

@media (max-width: 600px) {
    .responsive-message-image {
        width: 100%;
        height: 300px;
    }
    .responsive-fieldset{
        width: 100%;
    }
}

@media (max-width: 550px) {
    .responsive-message-image {
        width: 100%;
        height: 300px;
    }
    .responsive-fieldset{
        width: 100%;
    }
}

@media (max-width: 350px) {
    .responsive-message-image {
        width: 50%;
        height: 300px;
    }
    .responsive-fieldset{
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/atoms/LoginOptionsComponent.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;IACI,UAAU;AACd;;AAEA,6CAA6C;;AAE7C;IACI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,UAAU;IACd;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;QACX,aAAa;IACjB;IACA;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,UAAU;QACV,aAAa;IACjB;IACA;QACI,WAAW;IACf;AACJ","sourcesContent":[".responsive-message-image {\n    width: 100%;\n    height: 300px;\n}\n\n.responsive-fieldset{\n    width: 50%;\n}\n\n/* Media Queries for Different Screen Sizes */\n\n@media (max-width: 1300px) {\n    .responsive-message-image {\n        width: 100%;\n        height: 300px;\n    }\n    .responsive-fieldset{\n        width: 50%;\n    }\n}\n\n@media (max-width: 900px) {\n    .responsive-message-image {\n        width: 100%;\n        height: 300px;\n    }\n    .responsive-fieldset{\n        width: 80%;\n    }\n}\n\n@media (max-width: 768px) {\n    .responsive-message-image {\n        width: 100%;\n        height: 300px;\n    }\n    .responsive-fieldset{\n        width: 90%;\n    }\n}\n\n@media (max-width: 600px) {\n    .responsive-message-image {\n        width: 100%;\n        height: 300px;\n    }\n    .responsive-fieldset{\n        width: 100%;\n    }\n}\n\n@media (max-width: 550px) {\n    .responsive-message-image {\n        width: 100%;\n        height: 300px;\n    }\n    .responsive-fieldset{\n        width: 100%;\n    }\n}\n\n@media (max-width: 350px) {\n    .responsive-message-image {\n        width: 50%;\n        height: 300px;\n    }\n    .responsive-fieldset{\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
