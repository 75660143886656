import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Dashboard } from "../organisms/Dashboard";
import { LoginOptionsComponent } from "../atoms/LoginOptionsComponent";
import { isAutheticated } from "../../util/AuthService";

export const Home = () => {
  const compound = useSelector((state) => state.compoundReducer);

  useEffect(() => {
    // if (instance.getAllAccounts().length > 0 && !getUserDetails()) {
    //   dispatch(getUserCall(API_GET_USER_DETAILS, REDUCER_SET_USER_DETAILS));
    // }
  });

  return (
    <>
      {/* <UnauthenticatedTemplate> */}
      {!isAutheticated() && <LoginOptionsComponent />}
      {/* </UnauthenticatedTemplate> */}

      {/* <AuthenticatedTemplate> */}
      {isAutheticated() && compound && compound.value !== "" && <Dashboard />}
      {/* </AuthenticatedTemplate> */}
    </>
  );
};
