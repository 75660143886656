const translationsEN = {
  TopNav: {
    dashboard: "Dashboard",
    arrival: "Arrival",
    lcp: "Load Complete",
    master: "Master",
    finalArrival: "Final Arrival",
    arrivalAnnouncement: "Arrival Announcement"
  },
  button: {
    signInButton: "Sign In",
    signOutButton: "Sign Out",
    search: "Search",
    reset: "Reset",
    save: "Save",
    updateArrival: "To Perform Arrival",
    deleteFromView: "Delete From View",
    updateLcp: "To Perform Load Complete",
    confirm: "Confirm",
    cancel: "Cancel",
    add: "Add",
    updateFinalArr: "To Perform Final Arrival",
    refresh: "Refresh",
    addHub: "Add Hub",
    downloadTemplate: "Download Template",
    exportToExcel: "Export to Excel",
    assignRoles: "Assign Roles",
  },
  label: {
    arrival: "Arrival",
    arrivalSearch: "Arrival Search",
    lcpSearch: "Load Complete Search",
    loadComplete: "Load Complete",
    finalArrival: "Final Arrival",
    finalArrSearch: "Final Arrival Search",
    etaFromDate: "ETA From Date",
    etaToDate: "ETA To Date",
    tarnsportIds: "Transport Id(s)",
    transportId: "Transport ID",
    vins: "VIN(s)",
    result: "Result",
    lcpResult: "Load Complete Result",
    finalArrResult: "Final Arrival Result",
    arrivalStatus: "Status",
    lcpStatus: "Status",
    compound: "Compound",
    compoundCode: "Compound Code",
    subCompound: "Sub-Compound",
    selectCompound: "Select Compound",
    arrivalConfirmation: "Arrival Confirmation",
    arrivalDate: "Arrival Date",
    arrivalTime: "Arrival Time",
    lcpConfirmation: "Load Complete Confirmation",
    lcpDate: "Load Complete Date",
    lcpTime: "Load Complete Time",
    finalArrConfirmation: "Final Arrival Confirmation",
    finalArrivalDate: "Final Arrival Date",
    finalArrivalTime: "Final Arrival Time",
    transportMethod: "Transport Method",
    truck: "Truck",
    rail: "Rail",
    truckingCompany: "Trucking Company",
    location: "Location",
    eta: "ETA",
    lp: "LP",
    vin: "VIN",
    model: "Model",
    colour: "Colour",
    destination: "Destination",
    lpCode: "LP Code",
    tmeTransportId: "TME Transport ID",
    externalId: "External ID",
    carrierId: "Carrier ID",
    destinationEta: "Destination ETA",
    licensePlate: "License Plate",
    wagonReferneceNumber: "Wagon Reference Number",
    truckPlate: "Truck Plate",
    trailerPlate: "Trailer Plate",
    compoundManagement: "Compound Management",
    userManagement: "User Management",
    roleMangement: "Role Management",
    compoundName: "Compound Name",
    compoundLocation: "Compound Location",
    compoundType: "Compound Type",
    active: "Active",
    inActive: "In-Active",
    action: "Action",
    addCompound: "Add Compound",
    editCompound: "Edit Compound",
    compounds: "Compounds",
    users: "Users",
    userId: "User Id",
    userName: "User Name",
    compoundAccess: "Compound Access",
    addUser: "Add User",
    editUser: "Edit User",
    status: "Status",
    vinsDetails: "VINs Details",
    compoundStationType: "Compound Station Type",
    mainCompound: "Main",
    roles: "Roles",
    truckingCompanies: "Trucking Companies",
    hubMaster: "Hub Master",
    hub: "Hub",
    hubName: "Hub Name",
    hubCode: "Hub Code",
    addHub: "Add Hub",
    toDate: "To Date - (From Date + 10 days)",
    fromDate: "From Date",
    arrived: "Arrived",
    inBound: "In Bound",
    inStock: "In Stock",
    login: "Login",
    loginWithAzureAd: "Login with Azure AD",
    loginWithTars: "Login with TARS",
    editRoles: "Edit Roles",
    assignRoles: "Assign Roles",
  },

  message: {
    unauthorized: "UNAUTHORIZED...Please sign in again.",
    forbidden: "FORBIDDEN...Please sign in again.",
    unauthenticatedMessage: "Please sign in to see the content of this page.",
    homePageLoginMessage: "Welcome To Logistic Partner Tools!!!",
    noCompoundAccess: "You do not have any Compound access.",
    noAccessToPage: "You don't have access to this page.",
    redirecting: "Redirecting...",
    noSubCompound: "No Sub-Compound",
    noData: "No data to show",
    selectLoginOption: "Please select login option",
  },
  errorMessage: {
    errorPageHeading: "Error Page",
    compoundNotSelected: "Please select a Compound.",
    networkError: "Network error. Please try again after sometime.",
    sessionExpired: "Session Expired"
  },
  footer: "© 2024 Copyright: Toyota Motor Europe, Version - 0.1",
};
export default translationsEN;
