// const alphaNumeric = /^([a-zA-Z0-9_ -]+)$/;
const alphaNumeric = /^([a-zA-Z0-9 ]+)$/;
const aplhabatic = /^([a-zA-Z]+)$/;

export function isNotBlankWithTrim(value, field) {
  if (!value.trim()) {
    return false;
  }
  return true;
}

export function isNotBlank(value) {
  if (!value) {
    return false;
  }
  return true;
}

export function isRadioButtonSelected(value) {
  if (!value) {
    return false;
  }
  return true;
}

export function isValidAlphaNumeric(value) {
  if (!value || value.trim().length <= 0) {
    return false;
  }
  if (value.match(alphaNumeric)) {
    return true;
  }
  return false;
}

export function isValidAlphabatic(value) {
  if (!value || value.trim().length <= 0) {
    return false;
  }
  if (value.match(aplhabatic)) {
    return true;
  }
  return false;
}
