import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { useTranslation } from 'react-i18next';
import { useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { API_POST_UPDATE_FINAL_ARRIVAL } from '../../actions/ApiConstants';
import { convertToYYYYMMDDWithoutSlash, convertToHHmmss } from '../../util/Utility';
import { Toast } from 'primereact/toast';
import { postCallWithoutReducer } from '../../actions/performAction';
import { BANNER_LIFE_TIME } from '../../util/Constants';

export const UpdateFinalArrivalDialog = (props) => {

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const toast = useRef(null);
    const [finalArrivalDate, setFinalArrivalDate] = useState(new Date);
    const [finalArrivalTime, setFinalArrivalTime] = useState(new Date);

    const onClickConfirm = () => {
        let finalArrivalDto = {
            subCompoundCode: props.selectedSubCompound,
            updateArrivalDto: {
                arrivalDate: convertToYYYYMMDDWithoutSlash(finalArrivalDate),
                arrivalTime: convertToHHmmss(finalArrivalTime),
                transportIds: Array.from(props.selectedFinalArrivalData.map((data) => data.transportId))
            }
        }

        dispatch(
            postCallWithoutReducer(
                API_POST_UPDATE_FINAL_ARRIVAL,
                finalArrivalDto,
                props.onSuccessSaveUpdateFinalArrival,
                onFailure
            )
        );
    }

    const onFailure = (error) => {
        toast.current.show({ severity: "error", summary: "Error", detail: error, life: BANNER_LIFE_TIME });
    };

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            {t("label.finalArrConfirmation")}
        </div>
    );

    const footerContent = (
        <div className='flex justify-content-end'>
            <div>
                <Button label={t("button.confirm")}
                    icon="pi pi-check" size='small'
                    onClick={onClickConfirm} />
            </div>
            <div>
                <Button label={t("button.cancel")} icon="pi pi-times"
                    onClick={() => props.setUpdateFinalArrivalDialogVisible(false)} size='small' />
            </div>
        </div>);

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Dialog visible={props.updateFinalArrivalDialogVisible} modal header={headerElement}
                footer={footerContent} onHide={() => props.setUpdateFinalArrivalDialogVisible(false)} closable
                breakpoints={{ '1000px': "60%", '800px': "70%", '667px': '25rem' }}
            >
                <div className='grid justify-content-center gap-4 m-2 pt-1'>
                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <Calendar inputId="finalArrivalDate"
                                value={finalArrivalDate} onChange={(e) => setFinalArrivalDate(e.value)}
                                dateFormat='dd/mm/yy'
                                showIcon />
                            <label htmlFor="finalArrivalDate">{t("label.finalArrivalDate")}</label>
                        </span>
                    </div>
                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <Calendar inputId="time"
                                value={finalArrivalTime}
                                onChange={(e) => setFinalArrivalTime(e.value)}
                                showIcon
                                timeOnly
                                dateFormat='hh:mm:ss'
                                icon={() => <i className='pi pi-clock' />} />
                            <label htmlFor="time">{t("label.finalArrivalTime")}</label>
                        </span>
                    </div>
                </div>
            </Dialog>
        </>
    );
};