import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog"
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Toast } from "primereact/toast"
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { API_POST_ADD_EDIT_ROLES } from "../../actions/ApiConstants";
import { DIALOG_BOX_EDIT, DIALOG_BOX_FOR_ADD } from "../../util/Constants";
import { Dropdown } from "primereact/dropdown";
import { postCallWithoutReducer } from "../../actions/performAction";

export const EditRolesDialog = (props) => {

    const toast = useRef(null);
    const [userId, setUserId] = useState(props.rowData ? props.rowData.userId : null);
    const [roles, setRoles] = useState(props.rowData ? props.rowData.roleNames : []);
    const [rolesList] = useState(props.rolesList);
    const [userIdList] = useState(props.userIdList);
    const [dialogBoxFor] = useState(props.dialogBoxFor);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    // Error States
    const [errorInUserId, setErrorInUserId] = useState({
        error: false,
        message: "",
    });

    const onClickSave = () => {

        let flag = false;

        if (dialogBoxFor === DIALOG_BOX_FOR_ADD) {
            if (!userId) {
                setErrorInUserId({ error: true, message: "Please enter user ID." });
                flag = true;
            }
        }

        if (!flag) {
            let addEditRoleDto = {
                userId: userId,
                roleNames: roles
            }
            dispatch(postCallWithoutReducer(API_POST_ADD_EDIT_ROLES, addEditRoleDto, props.onSuccessEditUserRole, onFailure))
        }

    }

    const onFailure = (error) => {
        toast.current.show({ severity: "error", summary: "Error", detail: error });
    };

    const headerElement = (
        <div className="inline-flex align-items-center justify-content-center gap-2">
            {dialogBoxFor === DIALOG_BOX_EDIT ? t("label.editRoles") : t("label.assignRoles")}
        </div>
    );

    const footerContent = (
        <div className="flex justify-content-end">
            <div>
                <Button
                    label={t("button.save")}
                    icon="pi pi-save"
                    onClick={onClickSave}
                    size="small"
                />
            </div>
            <div>
                <Button
                    label={t("button.cancel")}
                    icon="pi pi-times"
                    onClick={() => props.setEditDialogVisible(false)}
                    size="small"
                />
            </div>
        </div>
    );

    return (
        <>
            <Toast ref={toast} position="top-center" />
            <Dialog
                visible={props.editDialogVisible}
                modal
                header={headerElement}
                footer={footerContent}
                onHide={() => props.setEditDialogVisible(false)}
                closable
                breakpoints={{
                    "2000px": "40rem",
                    "1000px": "70%",
                    "800px": "70%",
                    "667px": "20rem",
                }}

            >
                <div className="grid justify-content-center mt-1 gap-2">

                    {dialogBoxFor === DIALOG_BOX_EDIT ?
                        <div className="col-12 md:w-7">
                            <span className="p-float-label">
                                <InputText
                                    id="userId"
                                    value={userId}
                                    className="w-full"
                                    disabled={true}
                                />
                                <label htmlFor="userId">
                                    {t("label.userId")}
                                    <b className="text-primary"> *</b>
                                </label>
                            </span>
                        </div>

                        :

                        <div className="col-12 md:w-7">
                            <span className="p-float-label">
                                <Dropdown
                                    value={userId}
                                    onChange={(e) => {
                                        setUserId(e.value);
                                        setErrorInUserId({ error: false, message: "" });
                                    }
                                    }
                                    options={userIdList}
                                    optionLabel="label"
                                    className={`w-full ${errorInUserId.error ? "p-invalid" : ""}`}
                                    showClear
                                    filter
                                />
                                <label htmlFor="userId">{t("label.userId")}
                                <b className="text-primary"> *</b>
                                </label>
                            </span>
                            <span
                                className="p-error block pt-1"
                                style={{ visibility: `${errorInUserId.error ? "" : "hidden"}` }}
                            >
                                {errorInUserId.message}
                            </span>
                        </div>
                    }

                    <div className="col-12 md:w-7">
                        <span className="p-float-label">
                            <MultiSelect
                                value={roles}
                                onChange={(e) => setRoles(e.value)}
                                options={rolesList}
                                optionLabel="label"
                                maxSelectedLabels={3}
                                className="w-full"
                                showClear
                                display="chip"
                                filter
                            />
                            <label htmlFor="Compound">{t("label.roles")}</label>
                        </span>
                    </div>
                </div>

            </Dialog>
        </>
    );
}