import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { getLabelfromValue, getPaginationRows } from "../../util/Utility";
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { AddOrEditCompoundDialog } from "./AddOrEditCompoundDialog";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getCallWithoutReducer } from "../../actions/performAction";
import {
  API_GET_ALL_COMPOUNDS,
  API_GET_TRUCKING_COMPANY,
} from "../../actions/ApiConstants";
import { DIALOG_BOX_EDIT, DIALOG_BOX_FOR_ADD } from "../../util/Constants";
import { Toast } from "primereact/toast";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";

export const CompoundManager = () => {
  useEffect(() => {
    dispatch(
      getCallWithoutReducer(
        API_GET_ALL_COMPOUNDS,
        "",
        setCompoundList,
        onFailure
      )
    );
    dispatch(
      getCallWithoutReducer(
        API_GET_TRUCKING_COMPANY,
        "",
        setTruckingCompanyList,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [addOrEditDialogVisible, setAddOrEditDialogVisible] = useState(false);
  const [dialogBoxFor, setDialogBoxFor] = useState("");
  const [rowToEditData, setRowToEditData] = useState();
  const [compoundList, setCompoundList] = useState();
  const [truckingCompanyList, setTruckingCompanyList] = useState([]);

  const onFailure = (error) => {
    toast.current.show({ severity: "error", summary: "Error", detail: error });
  };

  const actionOnCompoundDetails = (rowData) => {
    return (
      <div className=" flex justify-content-center text-primary">
        <div className="mr-3">
          <i
            className="pi pi-pencil font-semibold cursor-pointer"
            title="Edit"
            onClick={(e) => onClickEdit(e, rowData)}
          ></i>
        </div>
        {/* <div>
                <i className="pi pi-trash font-semibold cursor-pointer" title="Delete" onClick={(e) => onClickDelete(e, rowData)}></i>
            </div> */}
      </div>
    );
  };

  const onClickAddCompoundButton = () => {
    setRowToEditData("");
    setDialogBoxFor(DIALOG_BOX_FOR_ADD);
    setAddOrEditDialogVisible(true);
  };

  const onClickEdit = (e, rowData) => {
    setDialogBoxFor(DIALOG_BOX_EDIT);
    setRowToEditData(rowData);
    setAddOrEditDialogVisible(true);
  };

  const setSubCompoundsField = (rowData) => {
    return rowData.subCompounds?.map((subCompound) => (
      <div key={subCompound.subCompound}>
        {subCompound.subCompoundName} -
        {subCompound.arrival === true ? " Arrival" : ""}
        {subCompound.arrival && subCompound.loadComplete ? " & " : ""}
        {subCompound.loadComplete === true ? " Load Complete" : ""}
      </div>
    ));
  };

  const setTruckingCompaniesField = (rowData) => {
    return (
      <div>
        {rowData.truckingCompanies &&
          rowData.truckingCompanies.length !== 0 &&
          rowData.truckingCompanies
            .map((truckingCompany) => {
              return getLabelfromValue(truckingCompany, truckingCompanyList);
            })
            .join(", ")}
      </div>
    );
  };

  const setStatusField = (rowData) => {
    return rowData.status === true ? <div>Active</div> : <div>In-Active</div>;
  };

  const onSuccessSaveAddOrEditCompound = () => {
    toast.current.show({
      severity: "success",
      summary: "Compound saved",
      detail: "Compound saved succefully.",
    });

    dispatch(
      getCallWithoutReducer(
        API_GET_ALL_COMPOUNDS,
        "",
        setCompoundList,
        onFailure
      )
    );
    setAddOrEditDialogVisible(false);
  };
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    compoundName: { value: null, matchMode: FilterMatchMode.CONTAINS },
    compoundCode: { value: null, matchMode: FilterMatchMode.CONTAINS },
    compoundType: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const setResultTableHeader = () => {
    return (
      <div>
        <div className="inline">{t("label.compounds")}</div>

        <div className="flex justify-content-end">
          <span className="p-input-icon-left">
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue}
              onChange={onGlobalFilterChange}
              placeholder="Keyword Search"
            />
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      {addOrEditDialogVisible && (
        <AddOrEditCompoundDialog
          addOrEditDialogVisible={addOrEditDialogVisible}
          setAddOrEditDialogVisible={setAddOrEditDialogVisible}
          dialogBoxFor={dialogBoxFor}
          rowData={rowToEditData}
          onSuccessSaveAddOrEditCompound={onSuccessSaveAddOrEditCompound}
          truckingCompanyList={truckingCompanyList}
        />
      )}

      <Toast ref={toast} position="top-center" />

      <div className="flex justify-content-end">
        <Button
          label={t("label.addCompound")}
          icon="pi pi-plus"
          onClick={onClickAddCompoundButton}
          severity="primary"
        />
      </div>
      <br />
      <div id="compound-Manager-Table">
        <DataTable
          value={compoundList}
          scrollable
          scrollHeight="400px"
          header={setResultTableHeader}
          stripedRows
          tableStyle={{ minWidth: "50rem" }}
          dataKey="compoundCode"
          metaKeySelection={false}
          paginator
          rows={20}
          rowsPerPageOptions={getPaginationRows()}
          currentPageReportTemplate="{first} to {last} of {totalRecords}"
          paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
          dragSelection
          removableSort
          showGridlines
          filters={filters}
          globalFilterFields={["compoundName", "compoundCode", "compoundType"]}
        >
          <Column
            field="compoundName"
            header={t("label.compoundName")}
            sortable
          ></Column>
          <Column
            field="compoundCode"
            header={t("label.compoundCode")}
            sortable
          ></Column>
          <Column
            field="compoundType"
            header={t("label.compoundType")}
            sortable
          ></Column>
          <Column
            field="subCompounds"
            body={setSubCompoundsField}
            header={t("label.subCompound")}
            sortable
          ></Column>
          <Column
            field="truckingCompanies"
            body={setTruckingCompaniesField}
            header={t("label.truckingCompanies")}
            sortable
          ></Column>
          <Column
            field="status"
            body={setStatusField}
            header={t("label.status")}
            sortable
          ></Column>
          <Column
            header={t("label.action")}
            align={"center"}
            body={actionOnCompoundDetails}
          />
        </DataTable>
      </div>
    </>
  );
};
