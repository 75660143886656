import { REDUCER_UPLOAD_FILE_STATUS } from "./ReducerConstants";

export default function uploadRequestReducer(state = {
    blocking: false
}, action) {
    switch (action.type) {
        case REDUCER_UPLOAD_FILE_STATUS:
            return Object.assign({}, state, { uploadFileStatus: action.payload });
        default:
            return state;
    }
}