// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.arrivalTableClass .p-datatable-wrapper {
    min-height: 53vh !important;
    max-height: 53vh !important;
}

/* .arrivalTableClass .p-datatable-tbody {
    min-height: 59vh !important;
    max-height: 59vh !important;
} */

/* .arrivalTableClass .p-datatable-header {
    min-height: 6vh !important;
} */`, "",{"version":3,"sources":["webpack://./src/components/pages/Arrival.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2BAA2B;AAC/B;;AAEA;;;GAGG;;AAEH;;GAEG","sourcesContent":[".arrivalTableClass .p-datatable-wrapper {\n    min-height: 53vh !important;\n    max-height: 53vh !important;\n}\n\n/* .arrivalTableClass .p-datatable-tbody {\n    min-height: 59vh !important;\n    max-height: 59vh !important;\n} */\n\n/* .arrivalTableClass .p-datatable-header {\n    min-height: 6vh !important;\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
