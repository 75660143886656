// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    background: var(--surface-card);
    padding: 2rem;
    border-radius: 0px;
    margin-bottom: 1rem;
    align-items: left;
    margin-top: 4rem;
    float: left;
    
   
}

.cardMargin {
    background: var(--surface-card);
    padding: 2rem;
   
    border-radius: 8px;
    padding-top: 10%;
    margin-left: 30rem;
    align-items: right;

   
}
  
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,+BAA+B;IAC/B,aAAa;IACb,kBAAkB;IAClB,mBAAmB;IACnB,iBAAiB;IACjB,gBAAgB;IAChB,WAAW;;;AAGf;;AAEA;IACI,+BAA+B;IAC/B,aAAa;;IAEb,kBAAkB;IAClB,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;;;AAGtB","sourcesContent":[".card {\n    background: var(--surface-card);\n    padding: 2rem;\n    border-radius: 0px;\n    margin-bottom: 1rem;\n    align-items: left;\n    margin-top: 4rem;\n    float: left;\n    \n   \n}\n\n.cardMargin {\n    background: var(--surface-card);\n    padding: 2rem;\n   \n    border-radius: 8px;\n    padding-top: 10%;\n    margin-left: 30rem;\n    align-items: right;\n\n   \n}\n  \n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
