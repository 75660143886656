// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.vinDialogTableClass > .p-datatable .p-datatable-wrapper {
    min-height: 53vh !important;
    max-height: 53vh !important;
}`, "",{"version":3,"sources":["webpack://./src/components/organisms/VinDetailsDialogForArrival.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,2BAA2B;AAC/B","sourcesContent":[".vinDialogTableClass > .p-datatable .p-datatable-wrapper {\n    min-height: 53vh !important;\n    max-height: 53vh !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
