import { useNavigate } from "react-router";

const LogoComponent = () => {

    const navigate = useNavigate();

    const onClicklogo = () => {
        navigate("/");
    }
    return (
        <>
            <div className="lex-none flex align-items-center justify-content-center m-1">
                <img
                    src="logo_toyota.gif"
                    alt="TOYOTA"
                    width="50"
                    onClick={onClicklogo}
                    className="cursor-pointer"
                />
            </div>
        </>

    )
};

export default LogoComponent;