import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  REDUCER_SET_COMPOUND,
  REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY,
} from "../../reducer/ReducerConstants";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { getUserDetails, setSelectedCompound } from "../../util/SessionUtils";
import { sortCompoundListByLabel } from "../../util/Utility";

export const CompoundSelectorDialog = (prop) => {
  useEffect(() => shouldCompounEditIconVisible());

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const compoundList = sortCompoundListByLabel(
    getUserDetails()?.userCompoundAccessDtos
  ); //Array of compound Dtos

  const shouldCompounEditIconVisible = () => {
    if (compoundList?.length === 1) {
      dispatch({ type: REDUCER_SET_COMPOUND, payload: compoundList[0] });
      setSelectedCompound(compoundList[0]);
      // prop.setCompoundDialogVisible(false);
      dispatch({
        type: REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY,
        payload: false,
      });
      prop.setEditIconVisible(false);
    }
  };

  const [compound, setCompound] = useState(prop.compound.value); //String value
  const [error, setError] = useState(false);

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      {t("label.selectCompound")}
    </div>
  );

  const onClickSave = () => {
    if (!compound) {
      setError(true);
      return;
    }

    compoundList.map((compoundDto) => {
      if (compoundDto.value === compound) {
        dispatch({ type: REDUCER_SET_COMPOUND, payload: compoundDto });
        setSelectedCompound(compoundDto);
      }
      return null;
    });
    // prop.setCompoundDialogVisible(false);
    dispatch({
      type: REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY,
      payload: false,
    });
    prop.showToastOnCompoundChange();
    navigate("/");
  };

  const onSelectionChange = (e) => {
    setError(false);
    setCompound(e.value);
  };

  const footerContent = (
    <div>
      <Button
        label={t("button.save")}
        icon="pi pi-check"
        onClick={onClickSave}
        size="small"
      />
    </div>
  );

  return (
    <>
      <div className="flex justify-content-center">
        {getUserDetails() && (!compoundList || compoundList.length === 0) ? (
          <div className="fixed bottom-50 text-primary">
            <h1>{t("message.noCompoundAccess")}</h1>
          </div>
        ) : (
          <Dialog
            visible={prop.compoundDialogVisible}
            modal
            header={headerElement}
            footer={footerContent}
            style={{ width: "30%" }}
            onHide={() => {
              // prop.setCompoundDialogVisible(false);
              dispatch({
                type: REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY,
                payload: false,
              });
            }}
            closable={false}
            breakpoints={{ "667px": "20rem" }}
          >
            <div className=" grid justify-content-center">
              <div className="col-12 lg:w-7 md:w-7 ">
                <span className="p-float-label mt-3">
                  <Dropdown
                    inputId="compound"
                    value={compound}
                    onChange={(e) => onSelectionChange(e)}
                    options={compoundList}
                    // optionLabel="value"
                    className={`w-full ${error ? "p-invalid" : ""}`}
                    showClear={
                      compound === undefined || compound === "" ? false : true
                    }
                  />
                  <label htmlFor="compound">
                    {t("label.compound")} <b className="text-primary">*</b>
                  </label>
                </span>
                {error && (
                  <span className="p-error block pt-1">
                    {t("errorMessage.compoundNotSelected")}
                  </span>
                )}
              </div>
            </div>
          </Dialog>
        )}
      </div>
    </>
  );
};
