//id_token
export function setIdToken(id_token) {
  return sessionStorage.setItem("id_token", id_token);
}

export function getIdToken() {
  return sessionStorage.getItem("id_token");
}
//access_token
export function setAccessToken(id_token) {
  return sessionStorage.setItem("access_token", id_token);
}

export function getAccessToken() {
  return sessionStorage.getItem("access_token");
}
//account info
export function setAccount(account_info) {
  return sessionStorage.setItem("account_info", JSON.stringify(account_info));
}

export function getAccount() {
  return JSON.parse(sessionStorage.getItem("account_info"));
}

export function clearSessionStorage() {
  sessionStorage.clear();
}

//selected Compound
export function setSelectedCompound(selectedCompound) {
  return sessionStorage.setItem("selectedCompound", JSON.stringify(selectedCompound));
}

export function getSelectedCompound() {
  return JSON.parse(sessionStorage.getItem("selectedCompound"));
}

//selected Compound
export function setUserDetails(userDetails) {
  return sessionStorage.setItem("userDetails", JSON.stringify(userDetails));
}

export function getUserDetails() {
  return JSON.parse(sessionStorage.getItem("userDetails"));
}

export function setLoginMethod(loginMethod) {
  return sessionStorage.setItem("loginMethod", loginMethod);
}

export function getLoginMethod() {
  return sessionStorage.getItem("loginMethod");
}

export function setShowLoginOptionComponent(value) {
  return sessionStorage.setItem("showLoginOptionComponent", value);
}

export function getShowLoginOptionComponent() {
  return sessionStorage.getItem("showLoginOptionComponent");
}

export function idTokenExists() {
  return !!sessionStorage.getItem("id_token");
}

export function setSessionObject(sessionKey, sessionComponent) {
  return sessionStorage.setItem(sessionKey, JSON.stringify(sessionComponent));
}

export function getSessionObject(sessionKey) {

  let sessionComponent = sessionStorage.getItem(sessionKey);
  if (sessionComponent) {
      return JSON.parse(sessionComponent);
  }
  return null;
}