import { FilterMatchMode } from "primereact/api";
import { DataTable } from "primereact/datatable";
import { Toast } from "primereact/toast";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { getPaginationRows } from "../../util/Utility";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { EditRolesDialog } from "./EditRolesDialog";
import { getCallWithoutReducer } from "../../actions/performAction";
import { API_GET_ALL_ROLES_DROP_DOWN, API_GET_ALL_USER_ID_LIST, API_GET_ALL_USERS_AND_THEIR_ROLES } from "../../actions/ApiConstants";
import { Button } from "primereact/button";
import { DIALOG_BOX_EDIT, DIALOG_BOX_FOR_ADD } from "../../util/Constants";

export const RoleManager = () => {

    const { t } = useTranslation();
    const toast = useRef(null);
    const dispatch = useDispatch();
    const [editDialogVisible, setEditDialogVisible] = useState(false);
    const [rowToEditData, setRowToEditData] = useState();
    const [userRoleList, setUserRoleList] = useState();
    const [rolesList, setRolesList] = useState([]); //List of All roles of the application
    const [dialogBoxFor, setDialogBoxFor] = useState();
    const [userIdList, setUserIdList] = useState([]);

    useEffect(() => {
        dispatch(getCallWithoutReducer(API_GET_ALL_ROLES_DROP_DOWN, "", setRolesList, onFailure))
        dispatch(getCallWithoutReducer(API_GET_ALL_USERS_AND_THEIR_ROLES, "", setUserRoleList, onFailure))
        dispatch(getCallWithoutReducer(API_GET_ALL_USER_ID_LIST, "", setUserIdList, onFailure))
    }, [])

    const onSuccessEditUserRole = () => {
        toast.current.show({
            severity: "success",
            summary: "Role Changes Saved",
            detail: "Role changes saved succefully.",
        });

        dispatch(
            getCallWithoutReducer(API_GET_ALL_USERS_AND_THEIR_ROLES, "", setUserRoleList, onFailure)
        );
        setEditDialogVisible(false);
        setDialogBoxFor("")
        setRowToEditData()
    };

    const onFailure = (error) => {
        toast.current.show({ severity: "error", summary: "Error", detail: error });
    };

    const [globalFilterValue, setGlobalFilterValue] = useState("");
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        userId: { value: null, matchMode: FilterMatchMode.CONTAINS },
        roleNames: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });

    const onGlobalFilterChange = (e) => {
        const value = e.target.value;
        let _filters = { ...filters };

        _filters["global"].value = value;

        setFilters(_filters);
        setGlobalFilterValue(value);
    };

    const setResultTableHeader = () => {
        return (
            <div>
                <div className="inline">{t("label.roles")}</div>

                <div className="flex justify-content-end">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            value={globalFilterValue}
                            onChange={onGlobalFilterChange}
                            placeholder="Keyword Search"
                        />
                    </span>
                </div>
            </div>
        );
    };

    const actionOnUserRoleDetails = (rowData) => {
        return (
            <div className=" flex justify-content-center text-primary">
                <div className="">
                    <i
                        className="pi pi-pencil font-semibold cursor-pointer"
                        title="Edit"
                        onClick={(e) => onClickEdit(e, rowData)}
                    ></i>
                </div>
            </div>
        );
    };

    const onClickEdit = (e, rowData) => {
        setRowToEditData(rowData);
        setEditDialogVisible(true);
        setDialogBoxFor(DIALOG_BOX_EDIT)
    };


    const setRolesField = (rowData) => {
        return (
            <div>
                {rowData.roleNames &&
                    rowData.roleNames.length != 0 &&
                    rowData.roleNames.join(", ")}
            </div>
        );
    };

    const onClickAddButton = () => {
        setEditDialogVisible(true);
        setRowToEditData();
        setDialogBoxFor(DIALOG_BOX_FOR_ADD)
    }

    return (
        <>
            <Toast ref={toast} position="top-center" />

            {editDialogVisible && <EditRolesDialog setEditDialogVisible={setEditDialogVisible}
                editDialogVisible={editDialogVisible}
                rolesList={rolesList}
                onSuccessEditUserRole={onSuccessEditUserRole}
                rowData={rowToEditData}
                dialogBoxFor={dialogBoxFor}
                userIdList={userIdList}
            />}

            <div className="flex justify-content-end">
                <Button
                    label={t("button.assignRoles")}
                    icon="pi pi-plus"
                    onClick={onClickAddButton}
                    severity="primary"
                />
            </div>
            <br />

            <div id="user-Manager-Table">
                <DataTable
                    value={userRoleList}
                    scrollable
                    scrollHeight="400px"
                    header={setResultTableHeader}
                    filters={filters}
                    globalFilterFields={["userId", "roleNames"]}
                    stripedRows
                    tableStyle={{ minWidth: "50rem" }}
                    dataKey="userId"
                    metaKeySelection={false}
                    paginator
                    rows={20}
                    rowsPerPageOptions={getPaginationRows()}
                    currentPageReportTemplate="{first} to {last} of {totalRecords}"
                    paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                    dragSelection
                    removableSort
                    showGridlines
                >
                    <Column field="userId" header={t("label.userId")} sortable></Column>
                    <Column field="roleNames" header={t("label.roles")}
                        body={setRolesField}
                        sortable />
                    <Column
                        header={t("label.action")}
                        align={"center"}
                        body={actionOnUserRoleDetails}
                    />
                </DataTable>
            </div>

        </>
    );
}