import { REDUCER_SET_BLOCKING_STATE } from "./ReducerConstants";

export const blockingReducer = (state = { blocking: false }, action) => {
  switch (action.type) {
    case REDUCER_SET_BLOCKING_STATE:
      return Object.assign({}, state, { blocking: action.payload });
    default:
      return state;
  }
};
