import { Button } from "primereact/button";
import { useMsal } from "@azure/msal-react"; // Importing the useMsal hook from Azure MSAL for handling authentication.
import { logout } from "../../util/AuthService";
import { OverlayPanel } from "primereact/overlaypanel";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { getLoginMethod, getUserDetails } from "../../util/SessionUtils";
import { LOGIN_METH_AZURE } from "../../util/Constants";
import { logoutForTars } from "../../util/oidc/AuthServiceForTars";

export const SignOutButton = () => {

  const { t } = useTranslation();
  const { instance } = useMsal(); // Access the instance object from the useMsal hook.
  const userDetails = getUserDetails();
  const op = useRef(null);
  
  const handleLogout = () => {
    if (getLoginMethod() === LOGIN_METH_AZURE) {
      logout(instance);
    }
    else {
      logoutForTars();
    }
  };

  return (
    <>
      <div className="flex align-items-center mr-0 p-3 text-primary font-semibold h-full cursor-pointer red-line-on-hover"
        onClick={(e) => op.current.toggle(e)}>
        <span> {userDetails && userDetails.name} </span>
      </div>
      <div className="flex align-items-center">
        <Button
          color="inherit"
          icon="pi pi-power-off"
          onClick={handleLogout}
        />
        <OverlayPanel ref={op}>
          <span className="block font-bold text-primary">{t("label.roles")} :</span>
          <ul className="text-primary">
            {userDetails && userDetails.roles.map((role) => <li key={role}>{role}</li>)}
          </ul>
        </OverlayPanel>
      </div>
    </>
  );
};
