import { REDUCER_SET_USER_DETAILS } from "./ReducerConstants";

export const userReducer = (state = { userDetails: ""}, action) => {
  switch (action.type) {
    case REDUCER_SET_USER_DETAILS:
      return {
        ...state,
        userDetails: action.payload
      };
    default:
      return state;
  }
};
