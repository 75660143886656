import { useEffect, useRef } from "react";
import { setShowLoginOptionComponent } from "./SessionUtils";

export const AuthCallbackComponent = () => {

    const hasRun = useRef(false);

    useEffect(() => {
        if (hasRun.current) return;
        hasRun.current = true;
        setShowLoginOptionComponent(false);
    }, []);

    return (
        <div className="flex justify-content-center w-full">
            <div className="fixed text-primary"
                style={{ top: "45%" }}
            >
                <h1>Please wait...</h1>
            </div>
        </div>
    );
};