// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.responsive-graph {
  width: 300px;
  height: 440px;
}

/* Media Queries for Different Screen Sizes */
@media (min-width: 1300px) {
    .responsive-graph {
        width: 580px;
        height: 440px;
    }
  }

@media (max-width: 1300px ) {
    .responsive-graph {
        width: 470px;
        height: 440px;
    }
  }

  @media (max-width: 1000px) {
    .responsive-graph {
        width: 420px;
        height: 440px;
    }
  }

  @media (max-width: 900px) {
    .responsive-graph {
        width: 350px;
        height: 440px;
    }
  }

@media (max-width: 800px) {
    .responsive-graph {
        width: 320px;
        height: 440px;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-graph {
        width: 450px;
        height: 440px;
    }
  }

  @media (max-width: 500px) {
    .responsive-graph {
        width: 380px;
        height: 440px;
    }
  }

  @media (max-width: 350px) {
    .responsive-graph {
        width: 200px;
        height: 440px;
    }
  }`, "",{"version":3,"sources":["webpack://./src/components/organisms/Dashboard.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,aAAa;AACf;;AAEA,6CAA6C;AAC7C;IACI;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;AAEF;IACI;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;EAEA;IACE;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;EAEA;IACE;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;AAEF;IACI;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;EAEA;IACE;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;EAEA;IACE;QACI,YAAY;QACZ,aAAa;IACjB;EACF;;EAEA;IACE;QACI,YAAY;QACZ,aAAa;IACjB;EACF","sourcesContent":[".responsive-graph {\n  width: 300px;\n  height: 440px;\n}\n\n/* Media Queries for Different Screen Sizes */\n@media (min-width: 1300px) {\n    .responsive-graph {\n        width: 580px;\n        height: 440px;\n    }\n  }\n\n@media (max-width: 1300px ) {\n    .responsive-graph {\n        width: 470px;\n        height: 440px;\n    }\n  }\n\n  @media (max-width: 1000px) {\n    .responsive-graph {\n        width: 420px;\n        height: 440px;\n    }\n  }\n\n  @media (max-width: 900px) {\n    .responsive-graph {\n        width: 350px;\n        height: 440px;\n    }\n  }\n\n@media (max-width: 800px) {\n    .responsive-graph {\n        width: 320px;\n        height: 440px;\n    }\n  }\n  \n  @media (max-width: 768px) {\n    .responsive-graph {\n        width: 450px;\n        height: 440px;\n    }\n  }\n\n  @media (max-width: 500px) {\n    .responsive-graph {\n        width: 380px;\n        height: 440px;\n    }\n  }\n\n  @media (max-width: 350px) {\n    .responsive-graph {\n        width: 200px;\n        height: 440px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
