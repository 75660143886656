import { REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY } from "./ReducerConstants";

export const compoundSelectorDialogVisibilityReducer = (state =
    {
        visible: true
    }
    , action) => {
    switch (action.type) {
        case REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY:
            return {
                ...state,
                visible: action.payload,
            };
        default:
            return state;
    }
};
