import React, { useEffect } from "react";
import NavBar from "./Navbar";
import Footer from "../atoms/Footer";
import { BlockUI } from "primereact/blockui";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setNavigateForPerformAction } from "../../util/Utility";
import { useMsal } from "@azure/msal-react";
import { authenticateWithAzureAD, isAutheticated } from "../../util/AuthService";
import { getShowLoginOptionComponent, setAccessToken, setAccount, setIdToken, setShowLoginOptionComponent } from "../../util/SessionUtils";
import { getUserCall } from "../../actions/performAction";
import { API_GET_USER_DETAILS } from "../../actions/ApiConstants";
import { REDUCER_SET_USER_DETAILS } from "../../reducer/ReducerConstants";

const Container = (props) => {
  const navigate = useNavigate();
  const { instance } = useMsal();
  const dispatch = useDispatch();

  useEffect(() => {
    //Automatic redirection to azure portal
    // authenticateWithAzureAD(instance);

    if (getShowLoginOptionComponent()){
      setShowLoginOptionComponent(getShowLoginOptionComponent());
    }
    else{
      setShowLoginOptionComponent(true);
    }
    // isAutheticated() ? setShowLoginOptionComponent(false) : setShowLoginOptionComponent(true) ;

    const handleRedirectResponse = async () => {
      try {
        const response = await instance.handleRedirectPromise();
        if (sessionStorage.getItem("msal.account.keys") && response !== null) {
          setIdToken(response.idToken);
          setAccessToken(response.accessToken);
          setAccount(response.account);
          setShowLoginOptionComponent(false);
          dispatch(getUserCall(API_GET_USER_DETAILS, REDUCER_SET_USER_DETAILS));
          console.log("response", response);
          navigate("/");
        }
      } catch (error) {
        console.error('Error handling redirect response:', error);
      }
    };
    handleRedirectResponse();
    setNavigateForPerformAction(navigate);
  }, [])

  const { blocking } = useSelector((state) => state.blocking);

  return (
    <>
      <BlockUI
        blocked={blocking}
        template={<i className="loader" />}
        fullScreen
      >
        <NavBar />
        <br />
        <div style={{ marginTop: "65px" }}>
          {React.Children.map(props.children, (child, i) => {
            return child;
          })}
        </div>
        <Footer />
      </BlockUI>
    </>
  );
};

export default Container;