import axios from "axios";
import { getIdToken, getSelectedCompound, setUserDetails } from "../util/SessionUtils";
import {
  ACCEPT_TYPE,
  ACCEPT_TYPE_DOWNLOAD,
  CONTENT_TYPE,
  CONTENT_TYPE_UPLOAD,
  ERROR_NETWORK,
  FILE_STATUS_DATA_HAS_ERROR,
  FILE_STATUS_UPLOADED_SUCCESSFULLY,
  NO_DATA_TO_UPLOAD_ERROR_CODE,
  NO_SUB_COMPOUND_FOUND_ERROR_CODE,
  ONLY_SKIPPED_RECORDS_ERROR_CODE,
  ROUTE_ERROR_PAGE,
  SUCCESS_WITH_SKIPPED_RECORDS_ERROR_CODE
} from "../util/Constants";
import FileDownload from "js-file-download";
import { REDUCER_SET_BLOCKING_STATE } from "../reducer/ReducerConstants";
import { navigate } from "../util/Utility";


// axios.defaults.baseURL = "https://api.velos-lp-tool-dev.toyota-europe.com/lptool";
console.log(process.env);
axios.defaults.baseURL = process.env.REACT_APP_AXIOS_BASE_URL;
var excelTemplateBaseUrl = process.env.REACT_APP_AXIOS_EXCEL_TEMPLATE_BASE_URL;

export function setBlockingState(isBlocked) {
  return function (dispatch) {
    dispatch({
      type: REDUCER_SET_BLOCKING_STATE,
      payload: isBlocked
    });
  };
}

export function saveSearch(type, data) {
  return function (dispatch) {
    dispatch({
      type: type,
      payload: data
    });
  };
}

export function getUserCall(url, type, onSuccess, onFailure) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios.get(url, {
      headers: {
        'Content-Type': CONTENT_TYPE,
        'Accept': ACCEPT_TYPE,
        'Authorization': 'Bearer ' + getIdToken()
      }
    })
      .then((response) => {
        dispatch(setBlockingState(false));
        dispatch({
          type: type,
          payload: response.data
        });
        setUserDetails(response.data)
        if (onSuccess) {
          onSuccess(response.data)
        }
      })
      .catch((err) => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.code }
          });
          return;
        }
        // else if (err.response.status === 401) {
        //   navigate(ROUTE_ERROR_PAGE, {
        //     state: { errorResponse: err.response.status }
        //   });
        // } 
        else {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      })
  }
}

export function postCall(url, inputJson, appFunction, type, onSuccess, onFailure) {
  return function (dispatch) {
    dispatch({
      type: type,
      payload: null
    });
    dispatch(setBlockingState(true));
    axios
      .post(url, inputJson, {
        headers: {
          "Content-Type": CONTENT_TYPE,
          Accept: ACCEPT_TYPE,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken(),
          'AppFunction': appFunction
        }
      })
      .then(response => {
        // dispatch(saveSearch(REDUCER_SHOW_NOTIFICATION, "true"))
        dispatch({
          type: type,
          payload: response.data
        });
        if (onSuccess) {
          onSuccess();
        }
        dispatch(setBlockingState(false));
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          //   dispatch(redirectToErrorPage(ROUTE_ERROR_PAGE, err.response.status));
          //   redirectToErrorPage(ROUTE_ERROR_PAGE, err.response.status);
          // dispatch(saveSearch(REDUCER_SHOW_NOTIFICATION, "false"))
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function exportFile(url, inputJson, type, onSuccess, onFailure) {
  return function (dispatch) {
    dispatch({
      type: type,
      payload: null
    });
    dispatch(setBlockingState(true));
    axios
      .post(url, inputJson, {
        headers: {
          Accept: ACCEPT_TYPE_DOWNLOAD,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken()
        },
        responseType: "arraybuffer"
      })
      .then(response => {
        if (response.status === 200) {
          // dispatch(saveSearch(REDUCER_SHOW_NOTIFICATION, "true"))
          FileDownload(response.data, response.headers.filename);
        }
        dispatch(setBlockingState(false));
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          //   dispatch(redirectToErrorPage(ROUTE_ERROR_PAGE, err.response.status));
          // dispatch(saveSearch(REDUCER_SHOW_NOTIFICATION, "false"))
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        }
      });
  };
}

export function downloadFile(url, inputJson, appFunction, onSuccess, onFailure) {
  return function (dispatch) {
    // dispatch({
    //   type: type,
    //   payload: null
    // });
    dispatch(setBlockingState(true));
    axios
      .get(url, {
        params: inputJson,
        headers: {
          "Content-Type": ACCEPT_TYPE_DOWNLOAD,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken(),
          "AppFunction": appFunction
        },
        responseType: "arraybuffer"
      })
      .then(response => {
        if (response.status === 200) {
          FileDownload(response.data, response.headers.filename);
        }
        dispatch(setBlockingState(false));
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function downloadExcelTemplateFile(templateFileName, onFailure) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get(excelTemplateBaseUrl + templateFileName, {
        responseType: 'blob',
      })
      .then(response => {
        if (response.status === 200) {
          FileDownload(response.data, templateFileName);
        }
        dispatch(setBlockingState(false));
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function uploadFile(url, inputJson, appFunction, onSuccess, onFailure) {
  return function (dispatch) {
    // dispatch({
    //   type: type,
    //   payload: null
    // });
    dispatch(setBlockingState(true));
    axios
      .post(url, inputJson, {
        headers: {
          "Content-Type": CONTENT_TYPE_UPLOAD,
          Accept: ACCEPT_TYPE,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken(),
          "AppFunction": appFunction
        },
        responseType: "arraybuffer"
      })
      .then(response => {
        dispatch(setBlockingState(false));

        if (response.headers.fileuploadstatus) {

          if (response.headers.fileuploadstatus === SUCCESS_WITH_SKIPPED_RECORDS_ERROR_CODE) {
            FileDownload(response.data, response.headers.filename);
            if (onSuccess) {
              onSuccess(SUCCESS_WITH_SKIPPED_RECORDS_ERROR_CODE);
              return;
            }
          }

          if (response.headers.fileuploadstatus === ONLY_SKIPPED_RECORDS_ERROR_CODE) {
            FileDownload(response.data, response.headers.filename);
            if (onSuccess) {
              onSuccess(ONLY_SKIPPED_RECORDS_ERROR_CODE);
              return;
            }
          }

          if (response.headers.fileuploadstatus === NO_SUB_COMPOUND_FOUND_ERROR_CODE) {
            if (onSuccess) {
              onSuccess(NO_SUB_COMPOUND_FOUND_ERROR_CODE);
              return;
            }
          }

          if (response.headers.fileuploadstatus === NO_DATA_TO_UPLOAD_ERROR_CODE) {

            if (onSuccess) {
              onSuccess(NO_DATA_TO_UPLOAD_ERROR_CODE);
              return;
            }
          }
        }

        //When file has errors
        if (response.headers.filename) {
          FileDownload(response.data, response.headers.filename);
          if (onSuccess) {
            onSuccess(FILE_STATUS_DATA_HAS_ERROR);
            return;
          }
        }

        //When file uploaded successfully
        if (onSuccess) {
          onSuccess(FILE_STATUS_UPLOADED_SUCCESSFULLY);
        }
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function getCall(url, inputJson, appFunction, type, onSuccess, onFailure) {
  return function (dispatch) {
    dispatch({
      type: type,
      payload: null
    });
    dispatch(setBlockingState(true));
    axios
      .get(url, {
        params: inputJson,
        headers: {
          "Content-Type": CONTENT_TYPE,
          Accept: ACCEPT_TYPE,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken(),
          "AppFunction": appFunction
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        if (response.status === 200) {
          // dispatch(saveSearch(REDUCER_SHOW_NOTIFICATION, "true"))
          dispatch({
            type: type,
            payload: response.data
          });
          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          console.log(err.response);
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function getCallWithoutReducer(url, inputJson, onSuccess, onFailure) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .get(url, {
        params: inputJson,
        headers: {
          "Content-Type": CONTENT_TYPE,
          Accept: ACCEPT_TYPE,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken()
        }
      })
      .then(response => {
        dispatch(setBlockingState(false));
        if (response.status === 200) {
          if (onSuccess) {
            onSuccess(response.data);
          }
        }
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          console.log(err)
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            console.log(err)
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function postCallWithoutReducer(url, inputJson, onSuccess, onFailure) {
  return function (dispatch) {
    dispatch(setBlockingState(true));
    axios
      .post(url, inputJson, {
        headers: {
          "Content-Type": CONTENT_TYPE,
          Accept: ACCEPT_TYPE,
          "Compound": getSelectedCompound()?.value,
          Authorization: "Bearer " + getIdToken()
        }
      })
      .then((response) => {
        dispatch(setBlockingState(false));
        if (onSuccess) {
          onSuccess();
        }
      })
      .catch(err => {
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      });
  };
}

export function getCallWithoutBlock(url, inputJson, appFunction, onSuccess, onFailure) {
  return function (dispatch) {
    axios.get(url, {
      params: inputJson,
      headers: {
        'Content-Type': CONTENT_TYPE,
        'Accept': ACCEPT_TYPE,
        "Compound": getSelectedCompound()?.value,
        'Authorization': 'Bearer ' + getIdToken(),
        'AppFunction': appFunction
      }
    })
      .then((response) => {
        if (response.status === 200) {
          if (onSuccess) {
            onSuccess(response.data);
          }
        }
        dispatch(setBlockingState(false));
      })
      .catch((err) => {
        console.log(err.response);
        dispatch(setBlockingState(false));
        if (err.code === ERROR_NETWORK) {
          if (onFailure) {
            onFailure(err.message);
            return;
          }
        }
        if (err.response.status === 401) {
          navigate(ROUTE_ERROR_PAGE, {
            state: { errorResponse: err.response.status }
          });
        } else {
          if (onFailure) {
            onFailure(err.response.data.error);
          }
        }
      })
  }
}
