import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { RadioButton } from "primereact/radiobutton";
import { MultiSelect } from "primereact/multiselect";
import {
  setUserDataInMultiSelect,
  shouldElementBeDisabled,
} from "../../util/Utility";
import {
  ACTIVE,
  DIALOG_BOX_EDIT,
  DIALOG_BOX_FOR_ADD,
  IN_ACTIVE,
} from "../../util/Constants";
import { useDispatch } from "react-redux";
import {
  getCallWithoutReducer,
  postCallWithoutReducer,
} from "../../actions/performAction";
import {
  API_GET_MAIN_COMPOUNDS,
  API_POST_ADD_USER,
  API_POST_EDIT_USER,
} from "../../actions/ApiConstants";
import { Toast } from "primereact/toast";
import { isValidAlphaNumeric } from "../../util/CommonValidations";

export const AddOrEditUserDialog = (props) => {
  useEffect(() => {
    dispatch(
      getCallWithoutReducer(
        API_GET_MAIN_COMPOUNDS,
        "",
        setCompoundList,
        onFailure
      )
    );
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const toast = useRef(null);
  const [userId, setUserId] = useState(
    props.rowData ? props.rowData.userId : ""
  );
  const [userName, setUserName] = useState(
    props.rowData ? props.rowData.userName : ""
  );
  const [status, setStatus] = useState(
    props.rowData ? (props.rowData.status ? ACTIVE : IN_ACTIVE) : ACTIVE
  );
  const [dialogBoxFor] = useState(props.dialogBoxFor);
  const [selectedCompounds, setSelectedCompounds] = useState(
    props.rowData
      ? setUserDataInMultiSelect(props.rowData.userCompoundAccessDtos) //string array
      : []
  );
  const [compoundList, setCompoundList] = useState([]); //list of all the compounds

  // Error States
  const [errorInUserId, setErrorInUserId] = useState({
    error: false,
    message: "",
  });
  const [errorInUserName, setErrorInUserName] = useState({
    error: false,
    message: "",
  });
  const [errorInStatus, setErrorInStatus] = useState({
    error: false,
    message: "",
  });

  // const compoundList = [
  //     {
  //         label: "CMP 1",
  //         value: "CMP 1"
  //     },
  //     {
  //         label: "CMP 2",
  //         value: "CMP 2"
  //     },
  //     {
  //         label: "CMP 3",
  //         value: "CMP 3"
  //     },
  //     {
  //         label: "CMP 4",
  //         value: "CMP 4"
  //     },
  //     {
  //         label: "CMP 5",
  //         value: "CMP 5"
  //     },
  // ]

  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      {dialogBoxFor === DIALOG_BOX_FOR_ADD
        ? t("label.addUser")
        : t("label.editUser")}
    </div>
  );

  const onClickSave = () => {
    let flag = false;

    // let validEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (dialogBoxFor === DIALOG_BOX_FOR_ADD) {
      if (!userId) {
        setErrorInUserId({ error: true, message: "Please enter user ID." });
        flag = true;
      }
      // else if (!validEmailRegex.test(userName)) {
      //     setErrorInUserId({ error: true, message: "Please enter a valid user ID" })
      //     flag = true;
      // }
      if (!userName) {
        setErrorInUserName({ error: true, message: "Please enter username." });
        flag = true;
      }

      if (userName && !isValidAlphaNumeric(userName)) {
        setErrorInUserName({ error: true, message: "Please enter valid username." });
        flag = true;
      }
    }

    if (!status) {
      setErrorInStatus({ error: true, message: "Please select status." });
      flag = true;
    }

    if (!flag) {
      let addUserDto = {
        userId: userId,
        name: userName,
        compoundAccess: selectedCompounds,
        status: status === ACTIVE ? true : false,
      };
      if (dialogBoxFor === DIALOG_BOX_FOR_ADD) {
        dispatch(
          postCallWithoutReducer(
            API_POST_ADD_USER,
            addUserDto,
            props.onSuccessSaveAddOrEditUser,
            onFailure
          )
        );
        return;
      }
      if (dialogBoxFor === DIALOG_BOX_EDIT) {
        dispatch(
          postCallWithoutReducer(
            API_POST_EDIT_USER,
            addUserDto,
            props.onSuccessSaveAddOrEditUser,
            onFailure
          )
        );
        return;
      }
    }
  };

  const onFailure = (error) => {
    toast.current.show({ severity: "error", summary: "Error", detail: error });
  };

  const footerContent = (
    <div className="flex justify-content-end">
      <div>
        <Button
          label={t("button.save")}
          icon="pi pi-save"
          onClick={onClickSave}
          size="small"
        />
      </div>
      <div>
        <Button
          label={t("button.cancel")}
          icon="pi pi-times"
          onClick={() => props.setAddOrEditDialogVisible(false)}
          size="small"
        />
      </div>
    </div>
  );

  return (
    <>
      <Toast ref={toast} position="top-center" />
      <Dialog
        visible={props.addOrEditDialogVisible}
        modal
        header={headerElement}
        footer={footerContent}
        onHide={() => props.setAddOrEditDialogVisible(false)}
        closable
        breakpoints={{
          "2000px": "40rem",
          "1000px": "70%",
          "800px": "70%",
          "667px": "20rem",
        }}
      >
        <div className="grid justify-content-center gap-3 m-2">
          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <InputText
                id="userId"
                value={userId}
                onChange={(e) => {
                  setUserId(e.target.value);
                  setErrorInUserId({ error: false, message: "" });
                }}
                className={`w-full ${errorInUserId.error ? "p-invalid" : ""}`}
                disabled={shouldElementBeDisabled(dialogBoxFor)}
              />
              <label htmlFor="userId">
                {t("label.userId")}
                <b className="text-primary"> *</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{ visibility: `${errorInUserId.error ? "" : "hidden"}` }}
            >
              {errorInUserId.message}
            </span>
          </div>

          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <InputText
                id="userName"
                value={userName}
                onChange={(e) => {
                  setUserName(e.target.value);
                  setErrorInUserName({ error: false, message: "" });
                }}
                className={`w-full ${errorInUserName.error ? "p-invalid" : ""}`}
                disabled={shouldElementBeDisabled(dialogBoxFor)}
              />
              <label htmlFor="userName">
                {t("label.userName")}
                <b className="text-primary"> *</b>
              </label>
            </span>
            <span
              className="p-error block pt-1"
              style={{ visibility: `${errorInUserName.error ? "" : "hidden"}` }}
            >
              {errorInUserName.message}
            </span>
          </div>

          <div className="col-12 md:w-7">
            <span className="p-float-label">
              <MultiSelect
                value={selectedCompounds}
                onChange={(e) => setSelectedCompounds(e.value)}
                options={compoundList}
                optionLabel="label"
                maxSelectedLabels={3}
                className="w-full"
                showClear
                display="chip"
                filter
              />
              <label htmlFor="Compound">{t("label.compound")}</label>
            </span>
          </div>

          <div className="col-12 md:w-7">
            <div className="flex justify-content-center">
              <label htmlFor="status" className=" mr-4 ">
                {t("label.status")}: <b className="text-primary">*</b>
              </label>

              <RadioButton
                inputId="status"
                name="status"
                value={ACTIVE}
                onChange={(e) => {
                  setStatus(e.value);
                  setErrorInStatus({ error: false, message: "" });
                }}
                checked={status === ACTIVE}
              />
              <label htmlFor="status" className="ml-2 mr-2">
                {t("label.active")}
              </label>

              <RadioButton
                inputId="status"
                name="status"
                value={IN_ACTIVE}
                onChange={(e) => {
                  setStatus(e.value);
                  setErrorInStatus({ error: false, message: "" });
                }}
                checked={status === IN_ACTIVE}
              />
              <label htmlFor="status" className="ml-2">
                {t("label.inActive")}
              </label>
            </div>
            <div className="flex justify-content-center">
              <span
                className="p-error block pt-1"
                style={{
                  visibility: `${errorInStatus.error ? "" : "hidden"}`,
                }}
              >
                {errorInStatus.message}
              </span>
            </div>
          </div>
        </div>
      </Dialog>
    </>
  );
};
