import { useTranslation } from "react-i18next";

export const UnauthenticatedComponent = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="flex justify-content-center">
                <div className="fixed bottom-50 text-primary">
                    <h1>{t("message.unauthenticatedMessage")}</h1></div>
            </div>
        </>
    );
};