import "./App.css";
import LpRoutes from "./routes";
import { PrimeReactProvider } from "primereact/api";
import { MsalProvider } from "@azure/msal-react";
import { OidcProvider } from "redux-oidc";
import { getUserManager } from "./util/oidc/AuthServiceForTars.jsx";
import { store } from "./util/store.jsx";

function App({ msalInstance }) {
  return (
    <MsalProvider instance={msalInstance}>
      <OidcProvider store={store} userManager={getUserManager()}> 
        <PrimeReactProvider>
          <LpRoutes />
        </PrimeReactProvider>
      </OidcProvider>
    </MsalProvider>
  );
}

export default App;
