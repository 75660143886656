import { useTranslation } from "react-i18next";

const Footer = () => {
    const { t } = useTranslation();
    return (
        <>
            <div className="bg-primary fixed bottom-0 w-full left-0 text-center z-5">
                <span>{t("footer")}</span>
            </div>
        </>
    );
};

export default Footer;