import { DIALOG_BOX_EDIT, ROLE_LOGISTIC_PARTNER_SUPPORT } from "./Constants";
import { getSelectedCompound, getUserDetails } from "./SessionUtils";
import moment from "moment";

export var navigate;
export var dispatch;

export const setNavigateForPerformAction = (useNavigateHook) => {
  navigate = useNavigateHook;
};

export function getPaginationRows() {
  return [20, 50, 100, 200];
}

export const userHaveWriteAccessOnSelectedCompound = () => {
  var selectedCompound = getSelectedCompound();

  if (selectedCompound && selectedCompound.access === "RW") {
    return true;
  }
  return false;
};

export const sortCompoundListByLabel = (compoundList) => {
  if (compoundList && compoundList.length > 1) {
    return Array.from(compoundList).sort((a, b) => {
      const compoundA = a.label.toUpperCase();
      const compoundB = b.label.toUpperCase();
      if (compoundA < compoundB) {
        return -1;
      }
      if (compoundA > compoundB) {
        return 1;
      }
      return 0;
    });
  }
  return compoundList;
};

export const setUserDataInMultiSelect = (compoundAccessList) => {
  var arr = [];
  compoundAccessList.map((compound) => arr.push(compound.value));
  return arr;
};

export const mapSelectedSubCompoundsToDto = (list) => {
  var arr = [];
  if (list.length > 0)
    list.map((obj) =>
      arr.push({
        subCompoundName: obj.subCompoundName,
        subCompoundCode: obj.subCompoundCode,
        arrival: obj.arrival,
        loadComplete: obj.loadComplete,
      })
    );
  return arr;
};

export const mapSelectedSubCompoundsToUi = (list) => { //list of sub compound 
  var arr = [];
  if (list.length > 0)
    list.map((subCompound) => arr.push(subCompound.subCompoundCode));
  return arr; //String array
};

export const getLabelfromValue = (
  value, // value or code
  listOfLableValueDtos  // ex: [{label: "", value: ""},..]
) => {
  for (let item of listOfLableValueDtos) {
    if (item.value === value) {
      return item.label;
    }
  }
  return value; //In case no match return code or value
};

export const shouldElementBeDisabled = (dialogBoxFor) => {
  if (dialogBoxFor === DIALOG_BOX_EDIT) {
    return true;
  }
  return false;
};

export const isUserMasterUser = () => {
  return getUserDetails()?.roles.includes(ROLE_LOGISTIC_PARTNER_SUPPORT)
    ? true
    : false;
};

export function dateComparision(startDate, endDate) {
  if (startDate > endDate) {
    return false;
  }
  return true;
}

export function isValidDate(inputDate, fieldName) {
  var dateRegEx =
    /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
  
  if (inputDate.match(dateRegEx)) {
    return true;
  } else {
    return false;
  }
}

export function formatToDDMMYYYY(input) {
  if (input) {
    var datePart = input.match(/\d+/g),
      year = datePart[0],
      month = datePart[1],
      day = datePart[2];

    return day + "/" + month + "/" + year;
  }
  return null;
}

export function formatYYYYMMDDToDDMMYYYY(input) {
  if (input && input.length === 8) {
    var year = input.substring(0, 4);
    var month = input.substring(4, 6);
    var day = input.substring(6, 8);

    return day + "/" + month + "/" + year;
  }
  return null;
}

export function convertToDDMMYYYY(inputDate) {
  if (inputDate) {
    return moment(inputDate).format("DD/MM/YYYY");
  }
  return null;
}

export function convertToHHmmss(inputDate) {
  if (inputDate) {
    return moment(inputDate).format("HHmmss");
  }
  return null;
}

export function convertTohhmmssWithColon(inputDate) {
  if (inputDate) {
    return moment(inputDate).format("hh:mm:ss");
  }
  return null;
}

export function convertToYYYYMMDDWithoutSlash(inputDate) {
  if (inputDate) {
    return moment(inputDate).format("YYYYMMDD");
  }
  return null;
}

export function convertToYYYYMMDD(inputDate) {
  if (inputDate) {
    return moment(inputDate).format("YYYY/MM/DD");
  }
  return null;
}

export function convertToDashYYYYMMDD(inputDate) {
  if (inputDate) {
    return moment(inputDate).format("YYYY-MM-DD");
  }
  return null;
}

export function convertToDashYYYYMMDDToDate(inputDate) {
  if (inputDate) {
    var date = new Date(moment(inputDate, "YYYY-MM-DD"));
    date.setHours(0, 0, 0, 0);
    return date;
  }
  return null;
}

export function convertToDDMMYYYYWithDate(inputDate) {
  if (inputDate) {
    return moment(new Date(inputDate)).format("DD/MM/YYYY");
  }
  return null;
}

export function convertToDDMMYYYYHHMMWithDate(inputDate) {
  if (inputDate) {
    return moment(new Date(inputDate)).format("DD/MM/YYYY HH:MM:ss");
  }
  return null;
}

export function convertToDDMMYYYYHHMMWithFullDate(inputDate) {
  if (inputDate) {
    return moment(new Date(inputDate).toISOString()).format("llll");
  }
  return null;
}

export function convertYYYYMMDDStringToDate(inputString) {
  if (inputString) {
    var date = new Date(inputString);
    date.setHours(0, 0, 0, 0);
    return date;
  }
  return null;
}

export function convertDDMMYYYStringToDate(inputString) {
  if (inputString) {
    var dateMomentObject = moment(inputString, "DD/MM/YYYY");
    var dateObject = dateMomentObject.toDate();
    return dateObject;
  }
  return null;
}

export const sortDateInDataTable = (event, columnName) => {
  return event.data.sort((data1, data2) => {
    const date1 = createDateObjFromDDMMYYYY(data1[columnName]);
    const date2 = createDateObjFromDDMMYYYY(data2[columnName]);

    //Ascending order
    if (event.order === 1) {
      if (date1 < date2) {
        return -1;
      } else if (date1 > date2) {
        return 1;
      } else {
        return 0;
      }
    }
    //Descending order
    else {
      if (date1 > date2) {
        return -1;
      } else if (date1 < date2) {
        return 1;
      } else {
        return 0;
      }
    }
  });
}

export const createDateObjFromDDMMYYYY = (dateString) => {
  //dateString format should be DD/MM/YYYY
  const [day, month, year] = dateString.split('/');
  return new Date(`${year}-${month}-${day}`);
}

export function deepEqual(obj1, obj2) {
  if (obj1 === obj2) {
    return true;
  }

  if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
    return false;
  }

  let keys1 = Object.keys(obj1);
  let keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (let key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false;
    }
  }

  return true;
}

export const addSequenceNumberToEachItemInCollection = (data) => {
  let arr = [];

  if (!data) {
    return arr;
  }
  
  data.map((item, index) => (arr.push({
    ...item,
    sequenceNumber: index + 1,
  })));
  return arr
}