import {
  PublicClientApplication,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";
import {
  setIdToken,
  setAccessToken,
  setAccount,
  clearSessionStorage,
  setLoginMethod,
  getIdToken,
} from "./SessionUtils";
import { navigate } from "./Utility";
import { LOGIN_METH_AZURE, ROUTE_ERROR_PAGE, SESSION_EXPIRED } from "./Constants";

export const getPublicClientObject = () => {
  // return new PublicClientApplication({
  //   auth: {
  //     clientId: "6c1ca199-d8f6-4dd2-ba8b-000801550914",
  //     authority:
  //       "https://login.microsoftonline.com/41cb5478-1f8a-4a8e-a2b7-58bbc1198c52/",
  //     redirectUri: "/",
  //     postLogoutRedirectUri: "/",
  //   },
  // });

  return new PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_client_id,
      authority: process.env.REACT_APP_authority,
      redirectUri: process.env.REACT_APP_redirect_uri,
      postLogoutRedirectUri: process.env.REACT_APP_post_Logout_redirect_uri,
    }
  });
};

export async function authenticateWithAzureAD(instance) {

  setLoginMethod(LOGIN_METH_AZURE);

  const request = {
    scopes: ["user.read"],
    forceRefresh: true,
    refreshTokenExpirationOffsetSeconds: 1800,
  };
  // const request = { scopes: [process.env.REACT_APP_scope] };

  const redirectResponse = await instance.handleRedirectPromise();
  if (redirectResponse !== null) {
    setIdToken(redirectResponse.idToken);
    setAccessToken(redirectResponse.accessToken);
    setAccount(redirectResponse.account);
    // console.log(redirectResponse);
  } else {
    // MSAL.js v2 exposes several account APIs, logic to determine which account to use is the responsibility of the developer
    const account = instance.getAllAccounts()[0];

    const accessTokenRequest = {
      scopes: ["user.read"],
      account: account,
      forceRefresh: true,
    };
    if (account) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(function (accessTokenResponse) {
          setIdToken(accessTokenResponse.idToken);
          setAccessToken(accessTokenResponse.accessToken);
          setAccount(accessTokenResponse.account);
          // console.log(accessTokenResponse);
        })
        .catch(function (error) {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          if (error instanceof InteractionRequiredAuthError) {

            navigate(ROUTE_ERROR_PAGE, {
              state: { errorResponse: SESSION_EXPIRED }
            });

            setTimeout(
              function () {
                instance.acquireTokenRedirect(request);
              },
              4000
            );
          }
        });
    }
    else {
      instance.acquireTokenRedirect(request)
        .then((res) => console.log(res))
        .catch((error) => console.log(error));
    }
  }
  //   // instance
  //   // .acquireTokenPopup(request)
  //   // .then((tokenResponse) => {
  //   //   setIdToken(tokenResponse.idToken);
  //   //   setAccessToken(tokenResponse.accessToken);
  //   //   setAccount(tokenResponse.account);
  //   //   console.log(tokenResponse);
  //   // })
  //   // .catch(async (error) => {
  //   //   if (error instanceof InteractionRequiredAuthError) {
  //   //     console.log(error);
  //   //   }
  //   // });
}

export const logout = (instance) => {
  instance.logoutRedirect().then((resp)=>{clearSessionStorage();}); // Call the method from the instance object to initiate the sign-out process.
  clearSessionStorage();
  console.log(sessionStorage)
  navigate("/");
};

export const isAutheticated = () => {

  if (getIdToken() && !(getIdToken() === null || getIdToken() === undefined)) 
  return true;
  return false;
}