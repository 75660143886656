import React, { useEffect, useRef, useState } from "react";
import { Menubar } from "primereact/menubar";
import LogoComponent from "../atoms/LogoComponent";
import { SignOutButton } from "../atoms/SignOutButton";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router";
import { useTranslation } from "react-i18next";
import { CompoundSelectorDialog } from "./CompoundSelectorDialog";
import { useDispatch, useSelector } from "react-redux";
import { Toast } from "primereact/toast";
import { OverlayPanel } from "primereact/overlaypanel";
import { SideMenu } from "../atoms/SideMenu";
import { Tooltip } from "primereact/tooltip";
import { ROUTE_ERROR_PAGE } from "../../util/Constants";
import { REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY } from "../../reducer/ReducerConstants";
import { isAutheticated } from "../../util/AuthService";

const NavBar = () => {
  const isAuthenticated = isAutheticated();
  useEffect(() => {
    if (
      isAuthenticated &&
      (compound?.value == "") &&
        !(location.pathname === "/" || location.pathname === ROUTE_ERROR_PAGE || location.pathname === "/callbackazure")
    ) {
      gotToPage("/");
    }

    if (isAuthenticated && location.pathname === ROUTE_ERROR_PAGE) {
      // setCompoundDialogVisible(false)
      dispatch({
        type: REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY,
        payload: false,
      });
    }
  });

  const [editIconVisible, setEditIconVisible] = useState(true);
  // const isAuthenticated = useIsAuthenticated();
  const [sideMenuVisible, setSideMenuVisible] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const compoundDialogVisible = useSelector(
    (state) => state.compoundSelectorDialogVisibilityReducer.visible
  );
  const toast = useRef(null);
  const compound = useSelector((state) => state.compoundReducer); //compound Dto
  const op = useRef(null);
  const location = useLocation();

  const gotToPage = (path) => {
    navigate(path);
    setSideMenuVisible(false);
  };

  const showToastOnCompoundChange = () => {
    toast.current.show({
      severity: "success",
      summary: "Compound Changed",
      detail: "Compound Changed Succefully",
    });
  };

  return (
    <>
      <Menubar
        className="fixed w-full z-5 top-0 left-0"
        start={
          <div className="flex justify-content-center align-items-center">
            {isAuthenticated ? (
              <div>
                <i
                  className="pi pi-align-justify cursor-pointer text-primary text-2xl font-bold button-dark-red-on-hover"
                  onClick={() => setSideMenuVisible(true)}
                ></i>
              </div>
            ) : null}

            <div className="inline ml-3">
              <LogoComponent />
            </div>

            <div
              className={`text-primary font-semibold ml-2 text-sm " ${
                isAuthenticated ? "hidden" : ""
              } md:block`}
            >
              <span>| &nbsp; Logistic Partner Tool</span>
            </div>
          </div>
        }
        end={
          isAuthenticated ? (
            <div className="flex align-items-center" style={{ height: "55px" }}>
              <div
                className="flex h-full align-items-center red-line-on-hover cursor-pointer"
                onClick={(e) => op.current.toggle(e)}
              >
                <span className="text-primary text-sx font-semibold ">
                  &nbsp; {compound?.label}
                </span>
              </div>

              {editIconVisible && (
                <div
                  className="flex h-full align-items-center ml-2 change-compound-icon"
                  data-pr-tooltip="Change Compound"
                  data-pr-position="bottom"
                >
                  <i
                    className="pi pi-pencil text-primary text-sm font-semibold cursor-pointer button-dark-red-on-hover"
                    onClick={() => {
                      // setCompoundDialogVisible(true);
                      dispatch({
                        type: REDUCER_SET_COMPOUND_SELECTOR_VISIBILITY,
                        payload: true,
                      });
                    }}
                  />
                  <Tooltip target=".change-compound-icon" />
                </div>
              )}

              <SignOutButton />
            </div>
          ) : null
        }
      />

      <SideMenu
        sideMenuVisible={sideMenuVisible}
        setSideMenuVisible={setSideMenuVisible}
        gotToPage={gotToPage}
      />

      {isAuthenticated && compoundDialogVisible && (
        <CompoundSelectorDialog
          compoundDialogVisible={compoundDialogVisible}
          compound={compound} //label
          // setCompoundDialogVisible={setCompoundDialogVisible}
          showToastOnCompoundChange={showToastOnCompoundChange}
          setEditIconVisible={setEditIconVisible}
        />
      )}

      <Toast ref={toast} position="top-center" />
      <OverlayPanel ref={op} className="text-primary">
        {compound && compound.subCompounds ? (
          <div>
            <span className="font-bold">{t("label.subCompound")}:</span>
            <ul>
              {compound.subCompounds?.map((subCompound, index) => (
                <li key={index}>{subCompound.subCompoundName}</li>
              ))}
            </ul>
          </div>
        ) : (
          <div>
            <span>{t("message.noSubCompound")}</span>
          </div>
        )}
      </OverlayPanel>
    </>
  );
};

export default NavBar;
