import { WebStorageStateStore } from "oidc-client";
import { createUserManager } from "redux-oidc";
import { clearSessionStorage, getIdToken } from "../SessionUtils";

var userManager = null;
var user = null;

export const createUserManagerConfig = () => {

    const userConfigJson = {
        authority:  process.env.REACT_APP_Tars_authority,
        client_id:  process.env.REACT_APP_Tars_client_id,
        redirect_uri:  process.env.REACT_APP_Tars_redirect_uri,
        post_logout_redirect_uri:  process.env.REACT_APP_Tars_post_logout_redirect_uri,
        response_type:  process.env.REACT_APP_Tars_response_type,
        scope:  process.env.REACT_APP_Tars_scope,
        userStore: new WebStorageStateStore({ store: window.sessionStorage }),
    };
    console.log(userConfigJson);
    return userConfigJson;
};

export const getUserManager = () => {
    if (!userManager) {
        userManager = createUserManager(createUserManagerConfig());
    }
    return userManager;
};

export const loginWithTars = () => {
    return getUserManager().signinRedirect();
};

export const isLoggedIn = () => {
    return user !== null && !user.expired;
};

export const completeAuthentication = () => {
    console.log("Localstorage:", localStorage);

    return getUserManager()
        .signinRedirectCallback(window.location.href)
        .then(() => {
            return getUser();
        })
        .catch((error) => {
            console.log("Error in auth");
            console.log(error);
        });
};

export const silentCallBack = () => {
    getUserManager()
        .signgetUsinSilentCallback()
        .then((userdata) => {
            getUser().then((u) => {
                console.log(u);
            });
        })
        .catch((error) => {
            console.log("Error in auth");
            console.log(error);
        });
    console.log("Localstorage 5");
    console.log(localStorage);
};

export const getUser = async () => {
    const user = await getUserManager().getUser();
    console.log("user", user);
    if (!user) {
        return await getUserManager().signinRedirectCallback();
    }
    return user;
};

export const renewToken = () => {
    return getUserManager().signinSilent();
};

export const setExpiredAt = (expires_at) => {
    sessionStorage.setItem("expires_at", expires_at);
};

export const logoutForTars = () => {
    removeUser();
    if (window.location.href.indexOf("localhost") > 0) {
        getUserManager().signoutRedirect({ id_token_hint: getIdToken() });
        console.log("Logout")
    } else {
        window.location.href = process.env.REACT_APP_Tars_post_logout_redirect_uri;
    }
    clearSessionStorage();
};

export const removeUser = () => {
    getUserManager().removeUser();
};

export const getAuthDetails = (user) => {
    console.log(window.location.href.indexOf("localhost"))
    if (window.location.href.indexOf("localhost") > 0) {
        return user;
    } else {
        return user;
    }
};

// function createUserManagerConfig(config) {
//     const userConfigJson = {
//       client_id: "0oa1brw45h8zSadIG358",
//       scope: "openid email userDetail Roles",
//       response_type: "id_token token",
//       redirect_uri: "http://localhost:3002/callback",
//       authority: "https://dev-759363.okta.com/oauth2/default/v1/authorize",
//       post_logout_redirect_uri: "http://localhost:3000",
//       metadata: {
//         issuer: "https://dev-759363.okta.com/oauth2/default",
//         authorization_endpoint: "https://dev-759363.okta.com/oauth2/default/v1/authorize",
//         userinfo_endpoint: "https://dev-759363.okta.com/oauth2/v1/userinfo",
//         jwks_uri: "https://dev-759363.okta.com/oauth2/default/v1/keys",
//         end_session_endpoint: "https://dev-759363.okta.com/oauth2/v1/logout"
//       },
//       automaticSilentRenew: true,
//       filterProtocolClaims: true,
//       loadUserInfo: true
//     }
//     return userConfigJson;
//   };