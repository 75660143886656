import { configureStore } from "@reduxjs/toolkit";
import { blockingReducer } from "../reducer/blockingReducer";
import { userReducer } from "../reducer/userReducer.jsx";
import { compoundReducer } from "../reducer/compoundReducer.jsx";
import uploadRequestReducer from "../reducer/uploadRequestReducer.jsx";
import { compoundSelectorDialogVisibilityReducer } from "../reducer/compoundSelectorDialogVisibilityReducer.jsx";

export const store = configureStore({
  reducer: {
    blocking: blockingReducer,
    userReducer: userReducer,
    compoundReducer: compoundReducer,
    uploadRequestReducer: uploadRequestReducer,
    compoundSelectorDialogVisibilityReducer: compoundSelectorDialogVisibilityReducer
  },
});
