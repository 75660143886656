import { REDUCER_SET_COMPOUND } from "./ReducerConstants";

export const compoundReducer = (state =
  {
    label: "",
    value: "",
    access: "",
    subCompounds: []
  }
  , action) => {
  switch (action.type) {
    case REDUCER_SET_COMPOUND:
      return {
        ...state,
        label: action.payload.label,
        value: action.payload.value,
        access: action.payload.access,
        subCompounds: action.payload.subCompounds
      };
    default:
      return state;
  }
};
