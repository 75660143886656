// --------------GET APIs-------------------
export const API_GET_USER_DETAILS = "/getUserDetails";
export const API_GET_ARRIVAL_DETAILS = "/getArrivalDetails";
export const API_GET_VIN_DETAILS = "/getVinDetails";
export const API_GET_LCP_DETAILS = "/getLoadCompleteDetails";
export const API_GET_FINAL_ARRIVAL_DETAILS = "/getFinalArrivalDetails";
export const API_GET_SUB_COMPOUNDS = "/getSubCompounds";
export const API_GET_MAIN_COMPOUNDS = "/getMainCompounds";
export const API_GET_ALL_COMPOUNDS = "/getCompounds";
export const API_GET_ALL_USERS = "/getUsers";
export const API_GET_TRUCKING_COMPANY = "/getTruckingCompany";
export const API_GET_SUB_COMPOUNDS_OF_MAIN_COMPOUND = "/getSubCompoundsOfMainCompound";
// export const API_GET_ALL_HUBS = "/getAllHubs";
export const API_DOWNLOAD_ARRIVAL_EXCEL = "/downloadArrivalExcel";
export const API_DOWNLOAD_FINAL_ARRIVAL_EXCEL = "/downloadFinalArrivalExcel";
export const API_DOWNLOAD_LCP_EXCEL = "/downloadLoadCompleteExcel";
export const API_GET_TRUCKING_COMPANY_BY_COMPOUND = "/getTruckingCompanyByCompound"
export const API_GET_COUNT_OF_ARRIVAL_AND_LCP_BY_DATE = "/getCountOfArrivalAndLcpByDate"
export const API_GET_COUNT_OF_FINAL_ARRIVAL_BY_DATE = "/getCountOfFinalArrivalByDate"
export const API_GET_ALL_ROLES_DROP_DOWN = "/getAllRolesDropDown";
export const API_GET_ALL_USERS_AND_THEIR_ROLES = "/getAllUsersAndTheirRoles";
export const API_GET_ALL_USER_ID_LIST = "/getAllUserIdList";

// --------------POST APIs-------------------
export const API_POST_ADD_COMPOUND = "/addCompound";
export const API_POST_EDIT_COMPOUND = "/editCompound";
export const API_POST_ADD_USER = "/addUser";
export const API_POST_EDIT_USER = "/editUser";
export const API_POST_UPDATE_ARRIVAL = "/updateArrival";
export const API_UPLOAD_ARRIVAL_EXCEL = "/uploadArrival";
export const API_POST_UPDATE_LCP = "/updateLoadComplete";
export const API_UPLOAD_LCP_EXCEL = "/uploadLoadComplete";
export const API_POST_UPDATE_FINAL_ARRIVAL = "/updateFinalArrival";
export const API_UPLOAD_FINAL_ARRIVAL_EXCEL = "/uploadFinalArrival";
export const API_POST_ADD_HUB = "/addHub";
export const API_POST_EDIT_HUB = "/editHub";
export const  API_POST_ADD_EDIT_ROLES = "/addEditRoles"