import { TabView, TabPanel } from 'primereact/tabview';
import { CompoundManager } from '../organisms/CompoundManager';
import { UserManager } from '../organisms/UserManager';
import { UnauthenticatedComponent } from '../atoms/UnauthenticatedComponent';
import { useTranslation } from 'react-i18next';
import { isUserMasterUser } from '../../util/Utility';
import { isAutheticated } from '../../util/AuthService';
import { RoleManager } from '../organisms/RoleManger';

export const Master = () => {
    const { t } = useTranslation();
    return (
        <>
            {/* <AuthenticatedTemplate> */}

            {
            isAutheticated()
             &&
                <>
                    {
                    isUserMasterUser()
                     ?
                        <TabView>
                            <TabPanel header={t("label.compoundManagement")} >
                                <CompoundManager />
                            </TabPanel>
                            <TabPanel header={t("label.userManagement")}>
                                <UserManager />
                            </TabPanel>
                            <TabPanel header={t("label.roleMangement")} >
                             <RoleManager/>
                            </TabPanel>
                        </TabView>
                        :
                        <div className="flex justify-content-center">
                            <div className="fixed bottom-50 text-primary">
                                <h1>{t("message.noAccessToPage")}</h1></div>
                        </div>
                    }
                </>
            }
            {/* </ AuthenticatedTemplate> */}

            {/* <UnauthenticatedTemplate> */}
            {!isAutheticated() && <UnauthenticatedComponent />}
            {/* </UnauthenticatedTemplate> */}
        </>
    );
};